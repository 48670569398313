import React, { useEffect, useState } from "react";
import { listenAccueil, updateIntro, addImage, deleteImage, addSubscriber, getSubscribers, deleteSubscriber, updateImageVisibility, getImageVisibility } from "./firestore";
import "./Accueil.css";
import Loader from "./Loader";
import Footer from './Footer';
import { useAuth } from "./AuthContext";
import { Helmet } from 'react-helmet';  // Import de React Helmet

const adminEmails = ["youenhamon@gmail.com", "victorleoleblanc@gmail.com"];

const Accueil = ({ hidden, toggleFooter }) => {
    const [accueil, setAccueil] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newIntro, setNewIntro] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [currentIntro, setCurrentIntro] = useState("");
    const [isAddingImage, setIsAddingImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageTitle, setImageTitle] = useState(""); 
    const [error, setError] = useState("");
    const [newsletterEmail, setNewsletterEmail] = useState("");
    const [showHelp, setShowHelp] = useState(false);
    const [showSubscribers, setShowSubscribers] = useState(false); // New state to show subscribers modal
    const [subscribers, setSubscribers] = useState([]); // New state to store subscribers
    const [successMessage, setSuccessMessage] = useState("")
    const { user } = useAuth();
    const isAdmin = user && adminEmails.includes(user.email);
    const [showImages, setShowImages] = useState(true); // Par défaut, les images sont visibles

    useEffect(() => {
        const unsubscribe = listenAccueil((newAccueil) => {
            setAccueil(newAccueil);
            if (newAccueil.length > 0) {
                setCurrentIntro(newAccueil[0].intro);
            }
            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    const handleUpdateIntro = async (e) => {
        e.preventDefault();
        try {
            await updateIntro(newIntro);
            setIsEditing(false);
        } catch (err) {
            setError(`Erreur lors de la mise à jour de l'intro : ${err.message}`);
        }
    };

    const startEditing = () => {
        setIsEditing(true);
        setNewIntro(currentIntro);
    };

    const handleImageUpload = async (e) => {
        e.preventDefault();
        if (selectedImage && imageTitle) {
            try {
                await addImage(selectedImage, imageTitle);
                setSelectedImage(null);
                setImageTitle(""); 
                setIsAddingImage(false);
            } catch (err) {
                setError(`Erreur lors du téléchargement de l'image : L'image existe déjà !`);
            }
        } else {
            setError("Veuillez sélectionner une image et entrer un titre.");
        }
    };

    const handleImageDelete = async (imageUrl, imageTitle) => {
        try {
            await deleteImage(imageUrl, imageTitle);
        } catch (err) {
            setError(`Erreur lors de la suppression de l'image : ${err.message}`);
        }
    };

    const handleNewsletterSignup = async (e) => {
        e.preventDefault();
        try {
            await addSubscriber(newsletterEmail);
            setNewsletterEmail("");
            setError("");
            setSuccessMessage("Vous avez bien été inscrit à la Newsletter de la Ferme du Fersac.");
            setTimeout(() => setSuccessMessage(""), 8000); // Efface le message après 5 secondes
        } catch (err) {
            setError(`Erreur lors de l'inscription à la newsletter : ${err.message}`);
        }
    };

    const handleShowSubscribers = async () => {
        try {
            const data = await getSubscribers();
            setSubscribers(data);
            setShowSubscribers(true);
        } catch (err) {
            setError(`Erreur lors de la récupération des abonnés : ${err.message}`);
        }
    };

    const handleDeleteSubscriber = async (id) => {
        try {
            await deleteSubscriber(id);
            setSubscribers(subscribers.filter(subscriber => subscriber.id !== id));
        } catch (error) {
            console.error("Erreur lors de la suppression de l'abonné :", error);
        }
    };

    const resetForm = () => {
        setSelectedImage(null);
        setImageTitle("");
        setError("");
        setIsAddingImage(false);
    };

    useEffect(() => {
        const fetchImageVisibility = async () => {
            const isVisible = await getImageVisibility();
            setShowImages(isVisible);
        };
        fetchImageVisibility();
    }, []);

    const toggleImagesVisibility = async () => {
        const newVisibility = !showImages;
        setShowImages(newVisibility);
        await updateImageVisibility(newVisibility);
    };

    return (
        <div hidden={hidden}>
            {/* Ajout de React Helmet pour la balise title et meta description */}
            <Helmet>
                <meta name="description" content="Réservez votre panier de fruits et légumes bio et frais à la Ferme du Fersac. Livraison locale et produits de qualité." />
                <title>La Ferme du Fersac - Fruits et légumes bio et locaux</title>
            </Helmet>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <div className="accueil-container">
                        <div className="accueil-content">
                            {accueil.map((element, index) => (
                                <div key={index} className="TOTO">
                                    {!isEditing ? (
                                        <>
                                            <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: element.intro }}></p>
                                            {isAdmin && (
                                                <>
                                                    <button className="edit-button" onClick={startEditing}>Modifier l'intro</button>
                                                    <button className="edit-button" onClick={() => setIsAddingImage(true)}>Ajouter/Supprimer des images</button>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <form className="test-form" onSubmit={handleUpdateIntro}>
                                            <textarea
                                                className="expanded-textarea"
                                                value={newIntro}
                                                onChange={(e) => setNewIntro(e.target.value)}
                                                placeholder="Enter HTML formatted text"
                                            />
                                            <div className="button-container">
                                                <button className="edit-button" type="submit">Mettre à jour</button>
                                                <button className="edit-button" type="button" onClick={() => setShowHelp(true)}>Aide</button>
                                                <button className="edit-button" type="button" onClick={() => setIsEditing(false)}>Annuler</button>
                                            </div>
                                        </form>
                                    )}
                                    {isAdmin && (
                <button 
                    className="edit-button" onClick={toggleImagesVisibility}>
                    {showImages ? 'Masquer les images' : 'Afficher les images'}
                </button>
            )}

            {showImages && (
                <>
                    <h2>Voici la liste approximative des fruits et légumes disponibles en ce moment:</h2>
                    <div className="images-container">
                        {element.image && element.image.map((imageObj, imageIndex) => (
                            <div key={imageIndex} className="image-wrapper">
                                {isAdmin && (
                                    <button className="delete-button" onClick={() => handleImageDelete(imageObj.url, imageObj.title)}>Supprimer</button>
                                )}
                                <img className="img1" src={imageObj.url} alt={`Image ${imageIndex}`} />
                                <p className="image-title">{imageObj.title}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="page-container">
                        <div className="newsletter-signup">
                            <h2>Abonnez-vous à la Newsletter !</h2>
                            <form onSubmit={handleNewsletterSignup}>
                            <input
                                type="email"
                                value={newsletterEmail}
                                onChange={(e) => setNewsletterEmail(e.target.value)}
                                placeholder="Votre adresse e-mail"
                                required
                            />
                            <button type="submit">S'inscrire</button>
                            </form>
                            {successMessage && <p className="success">{successMessage}</p>}
                            {error && <p className="error">{error}</p>}
                            {isAdmin && (
                                <button className="edit-button-3" onClick={handleShowSubscribers}>
                                    Voir les abonnés
                                </button>
                            )}
                        </div>
                    </div>

                    {showSubscribers && (
                        <div className="subscribers-overlay">
                            <div className="subscribers-content">
                                <h2>Liste des abonnés à la Newsletter</h2>
                                <ul>
                                    {subscribers.map((subscriber, index) => (
                                        <li key={index}>
                                            {subscriber.email}
                                            <button 
                                                className="button-sup"
                                                onClick={() => handleDeleteSubscriber(subscriber.id)}>   
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                                <button 
                                    className="button-fermer"
                                    onClick={() => setShowSubscribers(false)}>Fermer
                                </button>
                            </div>
                        </div>
                    )}

                    {isAddingImage && (
                        <div className="image-upload">
                            <form onSubmit={handleImageUpload}>
                                <h2>Ajouter une image</h2>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => setSelectedImage(e.target.files[0])}
                                />
                                <input
                                    type="text"
                                    value={imageTitle}
                                    onChange={(e) => setImageTitle(e.target.value)}
                                    placeholder="Titre du légume"
                                    required
                                />
                                <button type="submit">Ajouter l'image</button>
                                <button type="button" onClick={resetForm}>Annuler</button>
                                {error && <p className="error">{error}</p>}
                            </form>
                        </div>
                    )}

                    {showHelp && (
                        <div className="help-overlay">
                            <div className="help-content">
                                <h2>Aide</h2>
                                <p>Pour mettre un texte en gras : <code>&lt;b&gt;texte&lt;/b&gt;</code>
                                    <br/>
                                    Pour mettre un texte en italique : <code>&lt;i&gt;texte&lt;/i&gt;</code>
                                    <br/>
                                    Pour souligner du texte : <code>&lt;u&gt;texte&lt;/u&gt;</code>
                                    <br/>
                                    <br/>
                                    Pour modifier la taille du texte : <code>&lt;span style=&quot;font-size: 16px;&quot;&gt;texte&lt;/span&gt;</code> (16 correspond à la taille normal)
                                    <br/>
                                    <br/>
                                    Pour mettre du texte en bleu : <code>&lt;span style=&quot;color: blue;&quot;&gt;texte&lt;/span&gt;</code> (les couleurs sont en anglais)
                                    <br/> 
                                    Si tu veux des couleurs plus précises tu peux aller sur ce site : https://htmlcolorcodes.com/fr/ et tu prends la couleur en héxadécimale. 
                                    <br/> 
                                    <br/> 
                                    La couleur du tronc de l'arbre (vert foncé) en héxadécimale c'est #2A4C21
                                    <br/> 
                                    La couleur des feuilles de l'arbre (vert clair) en héxadécimale c'est #83BC2A
                                    <br/> 
                                    La couleur de la citrouille (orange) en héxadécimale c'est #F07D0C
                                    <br/> 
                                    <br/> 
                                    Du coup pour mettre du texte de la couleur du tronc de l'arbre tu fais : <code>&lt;span style=&quot;color: #2A4C21;&quot;&gt;texte&lt;/span&gt;</code>
                                    <br/> 
                                    <br/> 
                                    Enfin si maintenant tu veux mettre un texte en rouge et que tu veux aussi le souligner (par exemple) tu fais : <code>&lt;span style=&quot;color: red;&quot;&gt;&lt;u&gt;texte&lt;/u&gt;&lt;/span&gt;</code>
                                </p>
                                <div className="button-container">
                                    <button onClick={() => setShowHelp(false)}>Fermer</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <Footer setShowMentionsLegales={toggleFooter} isRelative={showImages} />
                </>
            )}
        </div>
    );
};

export default Accueil;