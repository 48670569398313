import React, { useState, useEffect } from "react";
import { addProduct, uploadProductImage, getProducts, getCartFromFirebase, addToCart, updateStock, increaseCartItem, removeFromCart, getProductDetails, subscribeToProductDetails, decreaseCartItem, checkAndHandleTimerExpiration, resetTimer, getTimer, startTimer, handleReservationSubmit, fetchProductPrices, getReservationData, saveAdminAvailability, getAdminAvailability, getAllReservations, updateProduct, deleteProduct} from "./firestore";
import "./Commande.css";
import Footer2 from './Footer2';
import { useAuth } from "./AuthContext";
import { auth, functions } from './firebase';
import { useRef } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from './Image/Logo.jpg'; // Assurez-vous que l'image est bien accessible
import PDF from './Image/format-de-fichier-pdf.png'
import panier from './Image/panier-en-osier.png'
import Loader from "./Loader";

const adminEmails = ["youenhamon@gmail.com", "victorleoleblanc@gmail.com"];




const Commande = ({ hidden, toggleFooter }) => {
    const [isAddingProduct, setIsAddingProduct] = useState(false);
    const [newProduct, setNewProduct] = useState({
        title: "",
        price: "",
        unit: "kg",
        minPortion: "",
        maxPortion: "",
        zone: "",
        subzone: "",
        image: "",
        available: true
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [zones, setZones] = useState([]);
    const [subzones, setSubzones] = useState([]);
    const [error, setError] = useState("");
    const [cart, setCart] = useState([]);
    const [produits, setProduits] = useState([]);
    const { user } = useAuth();
    const isAdmin = user && adminEmails.includes(user.email);
    const [disabledButtons, setDisabledButtons] = useState({});
    const [productDetails, setProductDetails] = useState({});
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [showReservationForm, setShowReservationForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [productQuantities, setProductQuantities] = useState({});
    const [showNewForm, setShowNewForm] = useState(false);
    const [showAvailabilityForm, setShowAvailabilityForm] = useState(false);
    const [availability, setAvailability] = useState({});
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [timeSlots, setTimeSlots] = useState([]);
    const [orders, setOrders] = useState([]);
    const [showOrderList, setShowOrderList] = useState(false);
    const [preparedOrders, setPreparedOrders] = useState({});
    const [isEditingProduct, setIsEditingProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [panierVisible, setPanierVisible] = useState(false);
    const [isPanierVisible, setIsPanierVisible] = useState(false);
    const [addedToCart, setAddedToCart] = useState({}); // État pour suivre les produits ajoutés
    const [cartItemCount, setCartItemCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
   
    

    // Fetch products on mount
    useEffect(() => {
        fetchProducts();
    }, []);

    // Fetch zones and subzones on mount
    useEffect(() => {
        setZones([
            { name: "Produits à la ferme", subzones: ["Légumes", "Fruits", "Plantes aromatiques", "Autres"] },
            { name: "Produits d'achat revente", subzones: ["Légumes", "Fruits", "Plantes aromatiques", "Autres"] }
        ]);
    }, []);

    // Update subzones when the selected zone changes
    useEffect(() => {
        const selectedZone = zones.find(zone => zone.name === newProduct.zone);
        setSubzones(selectedZone ? selectedZone.subzones : []);
    }, [newProduct.zone, zones]);

    // Fetch cart from Firebase when the user changes
    useEffect(() => {
        const fetchCart = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    console.log("Fetching cart for user:", user.uid);
                    const updatedCart = await getCartFromFirebase(user.uid);
                    console.log("Cart data:", updatedCart);
                    setCart(updatedCart.products || []); // Assurez-vous que `products` est défini
    
                    // Utilisez un objet pour stocker les fonctions d'unsubscribe
                    const unsubscribeMap = {};
    
                    const details = {};
                    for (const item of updatedCart.products) {
                        const unsubscribe = subscribeToProductDetails(item.productId, (productDetail) => {
                            if (productDetail) {
                                details[item.productId] = productDetail;
                                setProductDetails(prevDetails => ({ ...prevDetails, ...details }));
                            } else {
                                console.error(`Détails du produit non trouvés pour ${item.productId}`);
                            }
                        });
    
                        unsubscribeMap[item.productId] = unsubscribe;
                    }
    
                    // Nettoyez les listeners lorsque le composant est démonté
                    return () => {
                        Object.values(unsubscribeMap).forEach(unsubscribe => unsubscribe());
                    };
    
                } catch (error) {
                    console.error("Erreur lors de la récupération du panier :", error);
                }
            }
        };
    
        fetchCart();
    }, [user]);
    const intervalRef = useRef(null);

    useEffect(() => {
        const fetchAndSetTimer = async () => {
            const user = auth.currentUser;
            if (user && user.uid) {
                const timerData = await getTimer(user.uid);
                if (timerData) {
                    const now = Date.now();
                    const timeElapsed = now - timerData.startTime;
                    const remainingTime = 3600000 - timeElapsed; // 5 minutes
    
                    if (remainingTime > 0) {
                        setTimeRemaining(remainingTime);
                        startTimerInterval(remainingTime); // Démarrer le timer localement
                    } else {
                        await handleEmptyCart(user.uid); // Si le temps est déjà écoulé, videz le panier
                        await resetTimer(user.uid); // Réinitialiser le timer si le temps est écoulé
                        setTimeRemaining(null);
                    }
                } else {
                    setTimeRemaining(null);
                }
            }
        };
    
        fetchAndSetTimer();
    
        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current); // Nettoyez l'intervalle lorsque le composant est démonté
        };
    }, [user]);

    useEffect(() => {
        console.log("useEffect for timeRemaining and user triggered");
        if (timeRemaining !== null && timeRemaining <= 0) {
            const user = auth.currentUser;
            if (user && user.uid) {
                console.log("Calling resetTimer and handleEmptyCart due to timeRemaining being 0");
                resetTimer(user.uid).then(() => {
                    setTimeRemaining(null);
                });
                handleEmptyCart(user.uid);
            }
        }
    }, [timeRemaining, user]);

    useEffect(() => {
        console.log("Product Details après mise à jour:", productDetails);
    
        const updatedDisabledButtons = {};
    
        if (cart.length > 0 && Object.keys(productDetails).length > 0) {
            cart.forEach(item => {
                const product = productDetails[item.productId];
                if (product) {
                    updatedDisabledButtons[item.productId] = item.cartPortion >= product.maxPortion;
                }
            });
        }
    
        setDisabledButtons(updatedDisabledButtons);
    }, [cart, productDetails]);

    useEffect(() => {
        console.log("useEffect for visibility change triggered");
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'visible') {
                const user = auth.currentUser;
                if (user) {
                    console.log("Checking and handling timer expiration due to visibility change");
                    await checkAndHandleTimerExpiration(user.uid);
                    
                    const timerData = await getTimer(user.uid);
                    if (timerData) {
                        const now = Date.now();
                        const timeElapsed = now - timerData.startTime;
                        const remainingTime = 3600000 - timeElapsed;
    
                        if (remainingTime > 0) {
                            setTimeRemaining(remainingTime);
                        } else {
                            console.log("Timer expired, calling handleEmptyCart and resetTimer");
                            await handleEmptyCart(user.uid);
                            await resetTimer(user.uid);
                            setTimeRemaining(null);
                        }
                    } else {
                        setTimeRemaining(null);
                    }
                }
            }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (user && cart.length > 0) {
            const unsubscribeMap = {};
    
            cart.forEach(item => {
                const unsubscribe = subscribeToProductDetails(item.productId, (productDetail) => {
                    if (productDetail) {
                        setProductDetails(prevDetails => ({
                            ...prevDetails,
                            [item.productId]: productDetail
                        }));
                    }
                });
    
                unsubscribeMap[item.productId] = unsubscribe;
            });
    
            // Nettoyez les abonnements lors du démontage
            return () => {
                Object.values(unsubscribeMap).forEach(unsubscribe => unsubscribe());
            };
        }
    }, [user, cart]);


    useEffect(() => {
        const fetchAvailability = async () => {
            const data = await getAdminAvailability();
            console.log('Disponibilités récupérées:', data); // Vérifier les données ici
            if (data) {
                setAvailability(data);
            }
        };
    
        fetchAvailability();
    }, []);

    // Fetch products from Firebase
    const fetchProducts = async () => {
        try {
            const productsFromFirebase = await getProducts();
            setProduits(productsFromFirebase || []); // Ensure `productsFromFirebase` is defined
        } catch (error) {
            console.error("Erreur lors de la récupération des produits : ", error);
        }
    };

    const startTimerInterval = (initialTime) => {
        console.log("Starting timer interval...");
        if (intervalRef.current) clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            setTimeRemaining(prev => {
                const newTimeRemaining = prev - 1000;
                console.log(`Time remaining: ${newTimeRemaining}`);
                if (newTimeRemaining <= 0) {
                    clearInterval(intervalRef.current);
                    console.log("Timer expired, resetting...");
                    if (user && user.email) {
                        resetTimer(user.email);
                        handleEmptyCart(user.email);
                    }
                    return 0;
                }
                return newTimeRemaining;
            });
        }, 1000);
    };
    
    useEffect(() => {
        console.log("Checking if timer needs to start...");
        if (timeRemaining !== null && timeRemaining > 0 && !intervalRef.current) {
            startTimerInterval(timeRemaining);
        }
    }, [timeRemaining]);

    const updateButtonStates = (cartItems) => {
        const updatedDisabledButtons = {};
        cartItems.forEach(item => {
            const product = productDetails[item.productId];
            if (product) {
                // Désactivez le bouton si la quantité maximale est atteinte
                updatedDisabledButtons[item.productId] = item.cartPortion >= product.maxPortion;
            }
        });
        setDisabledButtons(updatedDisabledButtons);
    };

    // Update cart availability in the state
    const updateCartAvailability = (products) => {
        setCart(cart => cart.map(cartItem => {
            const product = products.find(p => p.title === cartItem.title);
            if (product) {
                return {
                    ...cartItem,
                    available: product.available
                };
            }
            return cartItem;
        }));
    };

    // Handle adding a new product
    const handleAddProduct = async (e) => {
        e.preventDefault();
        try {
            let imageUrl = "";
            if (selectedImage) {
                imageUrl = await uploadProductImage(selectedImage);
            }
            await addProduct({
                ...newProduct,
                image: imageUrl,
                available: true
            });
            setNewProduct({
                title: "",
                price: "",
                unit: "kg",
                minPortion: "",
                maxPortion: "",
                zone: "",
                subzone: "",
                image: "",
                available: true
            });
            setSelectedImage(null);
            setIsAddingProduct(true);
            setIsEditingProduct(false);
            fetchProducts();
        } catch (err) {
            setError(`Erreur lors de l'ajout du produit : ${err.message}`);
        }
    };

    const handleEditProduct = (item) => {
        setNewProduct({
            title: item.title,
            price: item.price,
            unit: item.unit,
            minPortion: item.minPortion,
            maxPortion: item.maxPortion,
            zone: item.zone,
            subzone: item.subzone,
            image: item.image,
            available: item.available
        });
        setSelectedProduct(item);
        setIsAddingProduct(true);
        setIsEditingProduct(true);
        fetchProducts();
    };

    const handleSubmitProduct = async (e) => {
        e.preventDefault();
        try {
            let imageUrl = newProduct.image;
    
            if (selectedImage) {
                imageUrl = await uploadProductImage(selectedImage);
            }
    
            // Déterminer la disponibilité en fonction de maxPortion et minPortion
            const availabilityStatus = parseInt(newProduct.maxPortion, 10) >= parseInt(newProduct.minPortion, 10);
    
            const updatedProduct = {
                ...newProduct,
                image: imageUrl,
                available: availabilityStatus // Set available based on the new logic
            };
    
            if (isEditingProduct) {
                await updateProduct(selectedProduct.id, updatedProduct);
            } else {
                await addProduct(updatedProduct);
            }
    
            // Réinitialiser les valeurs
            setNewProduct({
                title: "",
                price: "",
                unit: "kg",
                minPortion: "",
                maxPortion: "",
                zone: "",
                subzone: "",
                image: "",
                available: true
            });
            setSelectedImage(null);
            setIsAddingProduct(false);
            setIsEditingProduct(false);
            fetchProducts();
        } catch (err) {
            setError(`Erreur lors de l'ajout ou de la modification du produit : ${err.message}`);
        }
    };

    const handleDeleteProduct = async (productId) => {
        try {
            await deleteProduct(productId);
            fetchProducts(); // Actualisez la liste des produits après suppression
        } catch (error) {
            console.error(error);
            setError(`Erreur lors de la suppression du produit : ${error.message}`);
        }
    };

    // Handle adding a product to the cart
   
    const handleAddToCart = async (productId, product) => {
        const user = auth.currentUser;
    
        if (!user) {
            alert("Vous devez être connecté pour ajouter des produits au panier.");
            return;
        }
    
        try {
            setLoading(prev => ({ ...prev, [productId]: true })); // Début du chargement

            const updatedCart = await getCartFromFirebase(user.uid);
            const existingProduct = updatedCart.products.find(cartItem => cartItem.name === product.title);
    
            if (existingProduct) {
                alert("Le produit est déjà dans le panier.");
                return;
            }
    
            if (!product || !product.unit) {
                console.error("Produit ou son unité est manquante :", product);
                return;
            }
    
            const portionToAdd = product.unit === "kg" ? Number(product.minPortion) : 1;
    
            await addToCart(user.uid, productId);
            await updateStock(productId, -portionToAdd);
    
            if (updatedCart.products.length === 0) {
                await startTimer(user.uid);
                setTimeRemaining(3600000);
            }
    
            const updatedCartAfterAddition = await getCartFromFirebase(user.uid);
            setCart(updatedCartAfterAddition.products || []);
            setCartItemCount(updatedCartAfterAddition.products.length); // Mettez à jour le nombre de produits
    
            setAddedToCart((prev) => ({
                ...prev,
                [productId]: true,

                
            }));


            await checkAndHandleTimerExpiration(user.uid);
    
            console.log("Produit ajouté au panier avec succès !");
        } catch (error) {
            console.error("Erreur lors de l'ajout au panier :", error);
        } finally {
            setLoading(prev => ({ ...prev, [productId]: false })); // Fin du chargement
        }
    };

    
    

    const handleIncreaseCartItem = async (cartItemId) => {
        const user = auth.currentUser; // Utilisez l'auth importé pour obtenir l'utilisateur
    
        if (!user) {
            alert("Vous devez être connecté pour augmenter la portion d'un produit dans le panier.");
            return;
        }
    
        try {
            await increaseCartItem(user.uid, cartItemId);
            
            // Rechargez le panier pour mettre à jour l'affichage
            const updatedCartAfterIncrease = await getCartFromFirebase(user.uid);
            setCart(updatedCartAfterIncrease.products || []); // Assurez-vous que `products` est défini

            fetchProducts(); // Re-fetch products to ensure availability is updated
    
            console.log("Portion du panier augmentée avec succès !");
        } catch (error) {
            console.error("Erreur lors de l'augmentation de la portion du panier :", error);
        }
    };

    const handleRemoveFromCart = async (productId) => {
        const user = auth.currentUser;
    
        if (!user) {
            alert("Vous devez être connecté pour supprimer des produits du panier.");
            return;
        }
    
        try {
            setLoading(prev => ({ ...prev, [productId]: true })); // Début du chargement

            await removeFromCart(user.uid, productId);
    
            const updatedCartAfterRemoval = await getCartFromFirebase(user.uid);
            setCart(updatedCartAfterRemoval.products || []);
            setCartItemCount(updatedCartAfterRemoval.products.length); // Mettez à jour le nombre de produits
    
            setAddedToCart(prev => {
                const updatedState = {
                    ...prev,
                    [productId]: false
                };
                console.log("Updated addedToCart state after removal:", updatedState);
                return updatedState;
            });
    
            fetchProducts();
    
            console.log("Produit supprimé du panier avec succès !");
        } catch (error) {
            console.error("Erreur lors de la suppression du produit du panier :", error);
        } finally {
            setLoading(prev => ({ ...prev, [productId]: false })); // Fin du chargement
        }
    };

    useEffect(() => {
        const syncCartState = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    const cartFromFirebase = await getCartFromFirebase(user.uid);
                    const initialAddedToCartState = {};
    
                    // Mettre à jour l'état `addedToCart` en fonction des éléments dans le panier
                    cartFromFirebase.products.forEach(item => {
                        initialAddedToCartState[item.productId] = true;
                    });
    
                    setAddedToCart(initialAddedToCartState);
                    setCart(cartFromFirebase.products || []);
                    setCartItemCount(cartFromFirebase.products.length); // Initialiser le nombre de produits
                } catch (error) {
                    console.error("Erreur lors de la synchronisation du panier :", error);
                }
            }
        };
    
        syncCartState();
    }, []);

    useEffect(() => {
        const newAddedToCart = {};
    
        cart.forEach(item => {
            newAddedToCart[item.productId] = true;
        });
    
        setAddedToCart(newAddedToCart);
    }, [cart]);

    useEffect(() => {
        const fetchCart = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    const updatedCart = await getCartFromFirebase(user.uid);
                    setCart(updatedCart.products || []);
                    setCartItemCount(updatedCart.products.length); // Initialiser le nombre de produits
                } catch (error) {
                    console.error("Erreur lors de la récupération du panier :", error);
                }
            }
        };

        fetchCart();
    }, []);

    useEffect(() => {
        const handleTimerExpiration = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    await checkAndHandleTimerExpiration(user.uid);
    
                    // Rechargez le panier et mettez à jour l'état du panier
                    const updatedCartAfterExpiration = await getCartFromFirebase(user.uid);
                    setCart(updatedCartAfterExpiration.products || []);
                    setCartItemCount(updatedCartAfterExpiration.products.length); // Réinitialiser le nombre de produits
                } catch (error) {
                    console.error("Erreur lors de la gestion de l'expiration du timer :", error);
                }
            }
        };
    
        handleTimerExpiration();
    }, [timeRemaining]); // Réexécuter l'effet lorsque `timeRemaining` change

    useEffect(() => {
        setIsPanierVisible(false);
    }, []); // Le tableau de dépendances vide garantit que cet effet ne s'exécute qu'au montage

    const handleDecreaseCartItem = async (cartItemId) => {
        const user = auth.currentUser; // Utilisez l'auth importé pour obtenir l'utilisateur
    
        if (!user) {
            alert("Vous devez être connecté pour diminuer la portion d'un produit dans le panier.");
            return;
        }
    
        try {
            const success = await decreaseCartItem(user.uid, cartItemId);
            
            if (success === 'remove') {
                handleRemoveFromCart(cartItemId);
            }
    
            // Rechargez le panier pour mettre à jour l'affichage
            const updatedCartAfterDecrease = await getCartFromFirebase(user.uid);
            setCart(updatedCartAfterDecrease.products || []); // Assurez-vous que `products` est défini
    
            fetchProducts(); // Re-fetch products to ensure availability is updated
        
            console.log("Portion du panier diminuée avec succès !");
        } catch (error) {
            console.error("Erreur lors de la diminution de la portion du panier :", error);
        }
    };

    const formatTime = (time) => {
        if (time === null) return "00:00"; // Valeur par défaut lorsque le temps est null
    
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleEmptyCart = async () => {
        const user = auth.currentUser;
    
        if (!user) {
            alert("Vous devez être connecté pour vider le panier.");
            return;
        }
    
        try {
            // Récupérer le panier de Firebase
            const cartItems = await getCartFromFirebase(user.uid);
    
            // Supprimer chaque produit du panier
            for (const item of cartItems.products) {
                await removeFromCart(user.uid, item.id);
            }
    
            // Vider le panier local
            setCart([]);
    
            // Réinitialiser le timer
            await resetTimer(user.uid);
            fetchProducts();
    
            console.log("Panier vidé avec succès !");
        } catch (error) {
            console.error("Erreur lors du vidage du panier :", error);
        }
    };




    const renderPrice = (item) => {
        if (item.unit === "kg") {
            const portionPrice = item.minPortion !== "aucun" ? (item.price * (item.minPortion / 1000)).toFixed(2) : null;
            return (
                <p>
                    <em>{item.price.toFixed(2)} €/kg</em>
                    <br />
                    {item.minPortion !== "aucun" && (
    <>
        Portion min : {item.minPortion > 999
            ? `${(item.minPortion / 1000).toFixed(0)} kg`
            : `${item.minPortion} g`
        } ({portionPrice} €)
    </>
)}
                </p>
            );
        } else {
            return <p><em>{item.price.toFixed(2)} €/pièce</em> <br/> <br/></p>;
        }
    };

    const handleShowReservationForm = () => {
        setShowReservationForm(true);
    };

    const handleCloseReservationForm = () => {
        setShowReservationForm(false);
    };
    const handleOpenNewForm = () => {
        setShowReservationForm(false); // Optionnel : Ferme le formulaire de réservation si nécessaire
        setShowNewForm(true);
    };

    const calculateTotalPrice = () => {
        return cart.reduce((total, item) => total + parseFloat(item.price || 0), 0).toFixed(2);
    };

    const renderCartSummary = () => {
        return (
            <>
                <h2 className="cart-summary-title">Résumé de votre commande</h2>
                {cart.map(item => {
                    const portion = item.unit === "kg"
                        ? item.cartPortion > 999
                            ? `${(item.cartPortion / 1000).toFixed(2)} kg de`
                            : `${item.cartPortion} g de`
                        : `${item.cartPortion}`;
    
                    // Ajouter un 's' au nom de l'article si nécessaire
                    const itemName = item.unit !== "kg" && item.cartPortion > 1 && !item.name.endsWith("s")
                        ? `${item.name}s`
                        : item.name;
    
                    return (
                        <p key={item.id}>
                            - {portion} {itemName} pour une somme de {item.price}€
                        </p>
                    );
                })}
                <h3>Total : {calculateTotalPrice()}€ (ttc)</h3>
            </>
        );
    };

    
    
    const generatePDF = async (reservationData) => {
        console.log("Données de réservation:", reservationData);
        
        const doc = new jsPDF();
        
        try {
            const imgData = await getBase64ImageFromURL(logo); // Convertir en base64
            
            const shiftX = 10; // Décalage de 2 cm (20 mm) vers la droite
            const initialX = 10; // Coordonnée X initiale
            const initialY = 10; // Coordonnée Y initiale
            const width = 50; // Largeur de l'image
            const height = 50; // Hauteur de l'image
        
            doc.addImage(imgData, 'JPEG', initialX + shiftX, initialY, width, height); // Ajouter l'image (JPEG) avec le décalage
        } catch (error) {
            console.error("Erreur lors de l'ajout du logo :", error);
        }
        
        const shiftX = 80; // Décalage de 5 cm (50 mm) vers la droite
        const shiftY = 15; // Décalage de 3 cm (30 mm) vers le bas

        // Décalage horizontal et vertical
        doc.setFontSize(12);
        doc.text('Ferme du Fersac', 50 + shiftX, 15 + shiftY);
        doc.text('26 Rue Mle du Vautenet', 50 + shiftX, 20 + shiftY);
        doc.text('35270 Meillac', 50 + shiftX, 25 + shiftY);
        doc.text('lafermedufersac@gmail.com', 50 + shiftX, 30 + shiftY);
        doc.text('0766261603', 50 + shiftX, 35 + shiftY);
        
        const shiftY2 = 15; // Décalage de 3 cm (30 mm) vers le bas
        const initialY = 50; // Coordonnée Y initiale de la ligne

        // Dessiner la ligne avec le décalage vers le bas
        doc.setDrawColor(42, 76, 33);
        doc.setLineWidth(0.5);
        doc.line(10, initialY + shiftY2, 200, initialY + shiftY2); // Décalage vertical de 30 mm
        
        const shiftY3 = 20; // Décalage de 2 cm (20 mm) vers le bas

        doc.setFontSize(18);
        const title = `Panier de ${reservationData.prenom || 'Nom'} ${reservationData.nom || 'Prénom'}`;

        // Obtenir la largeur de la page
        const pageWidth = doc.internal.pageSize.width;

        // Calculer la largeur du texte
        const textWidth = doc.getTextWidth(title);

        // Calculer la position X pour centrer le texte
        const positionX = (pageWidth - textWidth) / 2;

        // Décalage vertical
        const initialY2 = 60;
        const positionY = initialY2 + shiftY3;

        // Ajouter le texte centré et déplacé vers le bas
        doc.text(title, positionX, positionY);
        
        const tableColumn = ["Produit", "Prix unitaire", "Quantité", "Prix total"];
        const tableRows = [];
        
        let totalPrice = 0;
        
        if (Array.isArray(reservationData.produits)) {
            for (const item of reservationData.produits) {
                    const productDetails = await getProductDetails(item.productId);
                    console.log("Données de commande", productDetails);
                    const itemPrice = productDetails.price; // Assurez-vous que price est bien défini
                    //const itemUnit = item.unité || 'pièce';
                    const itemQuantity = item.unité === "kg" 
                        ? item.quantité >= 1000 
                            ? `${(item.quantité / 1000).toFixed(2)} kg` 
                            : `${item.quantité} g`
                        : `${item.quantité}`;
                    const itemTotalPrice = item.prix;
        
                    totalPrice += parseFloat(itemTotalPrice);
        
                    const productData = [
                        item.nom,
                        `${itemPrice} €/${item.unité}`, // Afficher le prix unitaire avec l'unité
                        itemQuantity,
                        `${itemTotalPrice} €`
                    ];
        
                    tableRows.push(productData);
            }
        } else {
            console.error('Données de produits non valides:', reservationData.produits);
            return;
        }
        
        const shiftY4 = 20;
        const initialY4 = 70;
        const adjustedY = initialY4 + shiftY4;

doc.autoTable({
    startY: adjustedY,
    head: [tableColumn],
    body: tableRows,
    headStyles: { fillColor: [42, 76, 33] },
});
        
const shiftX5 = 5; // Décalage de 0,5 cm (5 mm) vers la droite

doc.setFontSize(12);

// Ajuster la position X en ajoutant le décalage
const adjustedX = 10 + shiftX5;

// Afficher le texte avec le décalage horizontal
doc.text(`Prix total du panier : ${totalPrice.toFixed(2)} € (ttc)`, adjustedX, doc.autoTable.previous.finalY + 10);

const date = reservationData.date || 'Date inconnue';
const heure = reservationData.heure || 'Heure inconnue';
const formattedDate = formatDate(date);
const formattedTime = formatTimePDF(heure);
        
const text = `Vous pouvez venir récupérer votre panier directement à la ferme le ${formattedDate || 'Date inconnue'} à ${formattedTime || 'Heure inconnue'}.`;

// Taille de la page et marge
const pageWidth6 = doc.internal.pageSize.width;
const margin = 10; // marge pour éviter le débordement du texte
const maxTextWidth = pageWidth6 - 2 * margin;

// Diviser le texte en lignes pour éviter les débordements
const lines = doc.splitTextToSize(text, maxTextWidth);

// Déplacer le texte de 1 cm (10 mm) vers le bas
const shiftY6 = 10; // Décalage de 1 cm (10 mm)
const initialY6 = doc.autoTable.previous.finalY + 20; // Position initiale
const adjustedY6 = initialY6 + shiftY6; // Nouvelle position Y

// Ajouter le texte en plusieurs lignes
doc.setFontSize(12);
doc.text(lines, margin, adjustedY6);
        
const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

// Position du texte précédent
const previousTextY = adjustedY6; // Position Y du texte précédent

// Définir la marge supplémentaire
const marginBelow = 30; // 2 cm (20 mm) en dessous
const shiftX7 = 10; // Décalage de 1 cm (10 mm) vers la gauche

// Calculer la nouvelle position Y
const adjustedY7 = previousTextY + marginBelow;

// Calculer la nouvelle position X
const initialX27 = 140; // Position X initiale
const adjustedX7 = initialX27 - shiftX7; // Décalage vers la gauche de 10 mm

// Ajouter le texte avec les nouvelles positions
doc.setFontSize(12);
doc.text("La ferme du Fersac vous remercie !", adjustedX7, adjustedY7);
        
        return doc.output('datauristring');
    };

    const formatDate = (dateString) => {
        const daysOfWeek = [
            'dimanche', 'lundi', 'mardi', 'mercredi', 
            'jeudi', 'vendredi', 'samedi'
        ];
    
        const months = [
            'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
            'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
        ];
        
        const [year, month, day] = dateString.split('-');
        
        // Créer une date à partir des composants
        const dateObject = new Date(`${year}-${month}-${day}`);
        
        // Récupérer le jour de la semaine et le mois
        const dayOfWeekName = daysOfWeek[dateObject.getDay()];
        const monthName = months[parseInt(month, 10) - 1];
        
        // Retourner la date formatée avec le jour de la semaine
        return `${dayOfWeekName} ${parseInt(day, 10)} ${monthName} ${year}`;
    };
    
    // Fonction pour formater l'heure au format 'HhMM'
    const formatTimePDF = (timeString) => {
        const [hours, minutes] = timeString.split(':');
        
        // Convertir en entier pour retirer les zéros non significatifs
        const hourFormatted = parseInt(hours, 10);
        const minuteFormatted = minutes.padStart(2, '0'); // Assurer que les minutes sont en deux chiffres
        
        return `${hourFormatted}h${minuteFormatted}`;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
    
        const user = auth.currentUser;
        if (!user) {
            alert("Vous devez être connecté pour valider la réservation.");
            return;
        }
    
        const formData = {
            date: e.target.date.value,
            time: e.target.time.value,
        };
    
        try {
            // Soumettre la réservation et obtenir l'ID
            const reservationId = await handleReservationSubmit(user.uid, formData, cart, setShowNewForm, setCart);
            if (!reservationId) {
                throw new Error("L'ID de réservation n'a pas été renvoyé.");
            }
    
            // Obtenir les données de réservation
            const reservationData = await getReservationData(reservationId);
    
            // Générer le PDF
            const pdfData = await generatePDF(reservationData);
    
            // Extraire la chaîne base64 du data URI
            const base64Data = pdfData.split(',')[1]; 
    
            // Appeler la fonction cloud Firebase pour envoyer l'email
            const sendEmailFunction = httpsCallable(functions, 'sendReservationEmail');
            await sendEmailFunction({ 
                email: user.email, 
                pdfData: base64Data, // Extraire la chaîne base64 du data URI
                userName: reservationData.prenom
            });
    
            alert('Votre commande à été validée ! Vous allez recevoir un récapitulatif par mail.');
        } catch (error) {
            console.error('Erreur lors de la validation de la réservation:', error);
            alert('Erreur lors de la validation de la réservation.');
        }
    };

    const getBase64ImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/jpeg');
                resolve(dataURL);
            };
            img.onerror = (error) => {
                reject(new Error('Erreur lors du chargement de l\'image : ' + error));
            };
        });
    };

    const toggleAvailabilityForm = (shouldShow) => {
        setShowAvailabilityForm(shouldShow);
    };

    const handleAddTimeSlot = (date, startTime, endTime) => {
        if (date && startTime && endTime) {
            const slot = `${startTime}-${endTime}`;
            setAvailability((prev) => ({
                ...prev,
                [date]: prev[date] ? [...prev[date], slot] : [slot]
            }));
        }
    };

    const handleSaveAvailability = async () => {
        await saveAdminAvailability(availability);
        alert('Disponibilités enregistrées avec succès !');
        setShowAvailabilityForm(false); // Ferme le formulaire après la sauvegarde
    };

    const removePastSlots = () => {
        // Obtenir la date et l'heure actuelles
        const now = new Date();
        const todayDate = now.toISOString().split('T')[0]; // Format "YYYY-MM-DD"
        const currentTime = now.toTimeString().split(' ')[0]; // Format "HH:MM:SS"
    
        // Filtrer les créneaux horaires pour garder seulement ceux dont la date est aujourd'hui ou après
        const filteredAvailability = Object.fromEntries(
            Object.entries(availability).filter(([date, slots]) => {
                // Comparez la date d'abord
                if (date > todayDate) {
                    return true; // Garder les dates futures
                } else if (date === todayDate) {
                    // Pour les créneaux du jour, vérifier les heures
                    return slots.some(slot => {
                        const [slotStart, slotEnd] = slot.split('-');
                        return slotEnd > currentTime; // Garder les créneaux qui n'ont pas encore fini
                    });
                }
                return false; // Éliminer les dates passées
            })
        );
    
        // Mettre à jour l'état avec les disponibilités filtrées
        setAvailability(filteredAvailability);
    };


    const handleDeleteTimeSlot = (date, slot) => {
        setAvailability((prev) => {
            const updatedSlots = prev[date].filter((s) => s !== slot);
            if (updatedSlots.length === 0) {
                const { [date]: _, ...rest } = prev; // Supprimer la date si aucun créneau n'est disponible
                return rest;
            }
            return {
                ...prev,
                [date]: updatedSlots
            };
        });
    };


    const convertToMinutes = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        return hours * 60 + (minutes || 0); // Convertir l'heure en minutes
    };
    
    const convertToTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours}:${mins === 0 ? '00' : (mins < 10 ? '0' + mins : mins)}`;
    };
    
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
        setSelectedTime(''); // Réinitialiser la sélection de l'heure quand la date change
    
        console.log("Disponibilités pour la date sélectionnée :", availability[date]);
    
        if (availability[date]) {
            const slots = [];
            availability[date].forEach((slot) => {
                const [start, end] = slot.split('-');
                let startTime = convertToMinutes(start);
                const endTime = convertToMinutes(end);
    
                while (startTime < endTime) { // Utiliser "<" au lieu de "<=" pour ne pas inclure l'heure de fin
                    slots.push(convertToTime(startTime));
                    startTime += 15; // Ajouter 15 minutes à chaque tranche
                }
                slots.push(convertToTime(endTime)); // Ajouter l'heure de fin exacte
            });
    
            console.log('Créneaux horaires générés:', slots); // Vérifier les créneaux horaires ici
            setTimeSlots(slots);
        } else {
            setTimeSlots([]); // Réinitialiser les créneaux horaires si aucune disponibilité
        }
    };
    
    const handleOpenPdf = async (order) => {
        try {
            const reservationData = await getReservationData(order.id);
            const pdfUri = await generatePDF(reservationData);
    
            // Ouvre le PDF dans un nouvel onglet
            const pdfWindow = window.open();
            if (pdfWindow) {
                // Définir le titre du document
                pdfWindow.document.write(`
                    <html>
                        <head>
                            <title>Panier de ${order.prenom} ${order.nom}</title>
                        </head>
                        <body style="margin:0">
                            <iframe width='100%' height='100%' src='${pdfUri}' style="border:none;"></iframe>
                        </body>
                    </html>
                `);
            } else {
                console.error("Le PDF n'a pas pu être ouvert. Peut-être que le pop-up est bloqué.");
            }
        } catch (error) {
            console.error("Erreur lors de l'ouverture du PDF:", error);
        }
    };

    const handleMarkAsPrepared = (orderId) => {
        setPreparedOrders(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    useEffect(() => {
        const fetchReservations = async () => {
            try {
                const reservations = await getAllReservations();
                if (Array.isArray(reservations)) {
                    setOrders(reservations);
                } else {
                    console.error('Les données de réservation ne sont pas valides:', reservations);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des commandes:', error);
            }
        };

        fetchReservations();
    }, []);

    const minDate = new Date(Date.now() + 48 * 60 * 60 * 1000);

    const togglePanierVisibility = () => {
        setIsPanierVisible(!isPanierVisible);
    };

    useEffect(() => {
        // Désactive le loader dès que les produits sont chargés, même si le tableau est vide
        setIsLoading(false);
    }, [produits]); // Le hook dépend de "produits", il se déclenchera à chaque fois que "produits" change

    


    return (
        <>
            <div hidden={hidden}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                {isAdmin && (
                    <div className="btn-admin">
                        <button className="add-button" onClick={() => setIsAddingProduct(true)}>
                            Ajouter un produit
                        </button>
                        <button className="command-button" onClick={() => setShowOrderList(true)} >
                            Commande
                        </button>
                        <button className="command-button" onClick={() => toggleAvailabilityForm(true)}>
                            Mes disponibilités
                        </button>

                    </div>
                )}
                
                <div className="commande-container">
                <div className="produits-container">
    {zones.map((section, index) => {
        // Filtrer les sous-zones pour ne garder que celles avec des produits
        const filteredSubzones = section.subzones.filter(subcategory => 
            produits.some(item => item.zone === section.name && item.subzone === subcategory)
        );

        // Si aucune sous-zone n'a de produits, ne pas afficher cette section
        if (filteredSubzones.length === 0) {
            return null;
        }

        return (
            <div key={index} className="section">
                <h2 className="titre_section">{section.name}</h2>
                {filteredSubzones.map((subcategory, idx) => (
                    <div key={idx} className="subcategory">
                        <h3 className="titre_section">{subcategory}</h3>
                        <div className="items-container">
                            {produits.filter(item => item.zone === section.name && item.subzone === subcategory).length > 0 ? (
                                produits.filter(item => item.zone === section.name && item.subzone === subcategory).map((item, itemIdx) => (
                                    <div key={itemIdx} className="item">
                                        <img src={item.image} alt={item.title} className="item-image" />
                                        <h3>{item.title}</h3>
                                        {renderPrice(item)}
                                        {item.available ? (
                                           <button
                                           key={item.id}
                                           className={`add-to-cart-btn ${addedToCart[item.id] ? 'added' : ''} ${loading[item.id] ? 'loading' : ''}`}
                                           onClick={() => {
                                               if (!loading[item.id]) {
                                                   addedToCart[item.id] ? handleRemoveFromCart(item.id) : handleAddToCart(item.id, item);
                                               }
                                           }}
                                           disabled={loading[item.id]} // Désactiver le bouton pendant le chargement
                                       >
                                           {loading[item.id] ? (
                                               <div className="spinner"></div> // Indicateur de chargement
                                           ) : addedToCart[item.id] ? (
                                               'Ajouté'
                                           ) : (
                                               'Ajouter au panier'
                                           )}
                                       </button>
                                        ) : (
                                            <button className="bouton-non-dispo" >Non disponible</button>
                                        )}
                                        {/* Bouton Modifier Visible pour l'Admin */}
                                        {isAdmin && (
                                            <div className="button-group">
                                                <button className="edit-product-btn" onClick={() => handleEditProduct(item)}>Modifier</button>
                                                <button className="delete-product-btn" onClick={() => handleDeleteProduct(item.id)}>Supprimer</button>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>Aucun produit disponible</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    })}
</div>

<div className="cart-icon" onClick={togglePanierVisibility}>
    <img src={panier} alt="Panier" />
    {cartItemCount > 0 && (
        <span className="cart-count">{cartItemCount}</span>
    )}
</div>
                    <div className="panier-container">
                        <div className="panier-header">
                            <h2>Panier</h2>
                            {user && cart.length > 0 && (
                            <div className="timer">
                            <h3>
                              Votre panier sera réinitialisé dans {formatTime(timeRemaining)}.
                              <span className="tooltip-icon">ℹ️
                                <span className="tooltip-text">Pour éviter les commandes sur des produits non disponibles, nous vous mettons ceux-ci de côté pendant 1h.</span>
                              </span>
                              <br/>
                              <br/>
                              Total : <span style={{ color: '#F07D0C' }}>{calculateTotalPrice()}€</span>
                            </h3>
                            <button className="validate-cart-btn" onClick={handleShowReservationForm}>
                              Valider mon panier
                            </button>
                          </div>
                            )}
                            {user && cart.length === 0 && (
                                <p>Le panier est vide.</p>
                            )}
                            {!user  && (
                                <p>Pour ajouter des produits à votre panier, vous devez être connecté.</p>
                            )}
                        </div>
                        {user && (
                            <>
                                {cart.length > 0 && (
                                    cart.map((item) => {
                                        // Déterminer si l'élément est disponible
                                        const productDetail = productDetails[item.productId] || {};
                                        const minPortion = productDetail.minPortion || 0;
                                        const maxPortion = productDetail.maxPortion || 0;

                                        // Vérifiez la disponibilité
                                        const isAvailable = minPortion <= maxPortion;

                                        console.log(`Item ${item.productId}: minPortion = ${minPortion}, maxPortion = ${maxPortion}, cartPortion = ${item.cartPortion}`);

                                        return (
                                            <div key={item.id} className="panier-item">
                                                <div className="item-header">
                                                    <img
                                                        src={item.image}  // Assurez-vous que cette URL est correcte
                                                        alt={item.name}
                                                        className="item-image"
                                                        onError={() => console.error(`Failed to load image at ${item.image}`)}  // Log en cas d'échec de chargement
                                                    />
                                                    <div className="info">
                                                        <div className="item-title">
                                                            {item.name}
                                                        </div>
                                                        <div className="item-quantity">
                                                            {item.unit === "kg" 
                                                                ? item.cartPortion >= 1000 
                                                                    ? `${(item.cartPortion / 1000).toFixed(2)} kg` 
                                                                    : `${item.cartPortion} g`
                                                                : `${item.cartPortion} pièce${item.cartPortion > 1 ? "s" : ""}`}
                                                        </div>
                                                    </div>
        
                                                </div>
                                                {console.log('Image URL:', item.image)} 
                                                {console.log('Item Data:', item)}
                                                <div className="item-details">
                                                    <div className="item-price">{item.price}€</div>
                                                </div>
                                                <div className="item-buttons">
                                                    <button className="decrease-btn"
                                                        onClick={() => handleDecreaseCartItem(item.id)}
                                                        disabled={disabledButtons[item.title]}>
                                                            -
                                                    </button>
                                                    {isAvailable === false || maxPortion === 0 ? (
                                                        <button className="increase-btn" disabled>
                                                             +
                                                        </button>
                                                    ) : (
                                                        <button className="increase-btn"
                                                            onClick={() => handleIncreaseCartItem(item.id)}
                                                            disabled={disabledButtons[item.title]}>
                                                                +
                                                        </button>
                                                    )}
                                                    <button className="remove-btn"
                                                        onClick={() => handleRemoveFromCart(item.id)}>
                                                            Supprimer
                                                    </button>
                                                </div>
                                            </div>
                                        );
                            
                                    })
                        
                                )}
                    
                            </>
                        )}
                    </div>
        {isPanierVisible && (
            <div className="panier-modal">
                <div className="modal-content">
                    <button className="close-button" onClick={togglePanierVisibility}>Fermer</button>
                    <div className="panier-header-mobile">
                            <h2>Panier</h2>
                            {user && cart.length > 0 && (
                            <div className="timer-mobile">
                                <h3>Votre panier sera réinitialisé dans {formatTime(timeRemaining)}.</h3>
                                <button className="validate-cart-btn-mobile" onClick={handleShowReservationForm}>
                                        Valider votre panier
                                    </button>
                            </div>
                            )}
                            {user && cart.length === 0 && (
                                <p>Le panier est vide.</p>
                            )}
                            {!user  && (
                                <p>Pour ajouter des produits à votre panier, vous devez être connecté.</p>
                            )}
                        </div>
                        {user && (
                            <>
                                {cart.length > 0 && (
                                    cart.map((item) => {
                                        // Déterminer si l'élément est disponible
                                        const productDetail = productDetails[item.productId] || {};
                                        const minPortion = productDetail.minPortion || 0;
                                        const maxPortion = productDetail.maxPortion || 0;

                                        // Vérifiez la disponibilité
                                        const isAvailable = minPortion <= maxPortion;

                                        console.log(`Item ${item.productId}: minPortion = ${minPortion}, maxPortion = ${maxPortion}, cartPortion = ${item.cartPortion}`);

                                        return (
                                            <div key={item.id} className="panier-item-mobile">
                                                <div className="item-header-mobile">
                                                    <img
                                                        src={item.image}  // Assurez-vous que cette URL est correcte
                                                        alt={item.name}
                                                        className="item-image-mobile"
                                                        onError={() => console.error(`Failed to load image at ${item.image}`)}  // Log en cas d'échec de chargement
                                                    />
                                                    <div className="info-mobile">
                                                        <div className="item-title-mobile">
                                                            {item.name}
                                                        </div>
                                                        <div className="item-quantity-mobile">
                                                            {item.unit === "kg" && item.cartPortion > 999 
                                                                ? `${(item.cartPortion / 1000).toFixed(2)} kg` 
                                                                : `${item.cartPortion} ${item.unit === "kg" ? "g" : "pièce"}`
                                                            }
                                                        </div>
                                                    </div>
        
                                                </div>
                                                {console.log('Image URL:', item.image)} 
                                                {console.log('Item Data:', item)}
                                                <div className="item-details-mobile">
                                                    <div className="item-price-mobile">{item.price}€</div>
                                                </div>
                                                <div className="item-buttons-mobile">
                                                    <button className="decrease-btn"
                                                        onClick={() => handleDecreaseCartItem(item.id)}
                                                        disabled={disabledButtons[item.title]}>
                                                            -
                                                    </button>
                                                    {isAvailable === false || maxPortion === 0 ? (
                                                        <button className="increase-btn" disabled>
                                                             +
                                                        </button>
                                                    ) : (
                                                        <button className="increase-btn"
                                                            onClick={() => handleIncreaseCartItem(item.id)}
                                                            disabled={disabledButtons[item.title]}>
                                                                +
                                                        </button>
                                                    )}
                                                    <button className="remove-btn"
                                                        onClick={() => handleRemoveFromCart(item.id)}>
                                                            Supprimer
                                                    </button>
                                                </div>
                                            </div>
                                        );
                            
                                    })
                        
                                )}
                    
                            </>
                        )}
                </div>
                
            </div>
                )}
        {showOrderList && (
                <div className="order-list">
                <h3 className="titre-list">Liste des commandes</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Date</th>
                            <th>Téléphone</th>
                            <th>PDF</th>
                            <th>À Préparer</th>
                            <th>Prêt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.length > 0 ? (
                            orders.map((order, index) => (
                                <tr key={index} style={{ backgroundColor: preparedOrders[order.id] ? 'lightgreen' : 'white' }}>
                                    <td>{order.nom}</td>
                                    <td>{order.prenom}</td>
                                    <td>{order.date}</td>
                                    <td>{order.tel}</td>
                                    <td>
                                        <img className="img2"
                                            src= {PDF} // Remplacez par le chemin de votre icône
                                            alt="PDF"
                                            onClick={() => handleOpenPdf(order)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={!preparedOrders[order.id]} readOnly />
                                    </td>
                                    <td>
                                        <button onClick={() => handleMarkAsPrepared(order.id)}>Prêt</button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">Aucune commande trouvée</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <button className="fermer-button" onClick={() => setShowOrderList(false)}>Fermer</button>
            </div>
            )}
        {showAvailabilityForm && (
            <div className="availability-form">
                <h3 className="titre-h3"> Choisissez vos jours et créneaux horaires de disponibilité</h3>

                <label>Date :</label>
                <input type="date" id="date" />

                <label>Heure de début :</label>
                <input type="time" id="startTime" />

                <label>Heure de fin :</label>
                <input type="time" id="endTime" />

                <br/>

                <button className="btn-dispo" onClick={() => handleAddTimeSlot(
                    document.getElementById('date').value,
                    document.getElementById('startTime').value,
                    document.getElementById('endTime').value
                )}>
                    Ajouter un créneau
                </button>

                <div className="added-time-slots">
                    <h3 className="titre-h3">Créneaux ajoutés :</h3>
                    <ul>
                        {Object.keys(availability).map(date => (
                            <li key={date} className="slot-item">
                                <div className="slot-content">
                                    <strong>{formatDate(date)} :</strong>
                                    {availability[date].map((slot, index) => (
                                        <span key={index}>
                                            {slot}
                                            <button className="delete-button" onClick={() => handleDeleteTimeSlot(date, slot)}>Supprimer</button>
                                        </span>
                                    ))}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                
                <button className="btn-dispo" onClick={handleSaveAvailability}>Enregistrer</button>
                <button className="btn-dispo" onClick={() => setShowAvailabilityForm(false)}>Fermer</button>
            </div>
        )}
        {showNewForm && (
            <div className="new-form">
                <div className="form-container-2">
                    <h4 className="info-text">
                        Choisissez une date et une heure pour aller chercher votre panier.
                    </h4>
                    <form onSubmit={handleFormSubmit}>
                        <label htmlFor="date">Date : &nbsp;</label>
                        <select id="date" name="date" value={selectedDate} onChange={handleDateChange} required>
                            <option value="">Sélectionnez une date</option>
                            {Object.keys(availability).filter(date => new Date(date) >= minDate).map((date) => (
                                <option key={date} value={date}>
                                    {formatDate(date)}
                                </option>
                            ))}
                        </select>
                        <br />
                        <br />

                        {/* Afficher le champ des heures si une date est sélectionnée */}
                        {selectedDate && (
                            <>
                                <label htmlFor="time">Heure : &nbsp;</label>
                                <select id="time" name="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} required>
                                    <option value="">Sélectionnez une heure</option>
                                    {timeSlots.length > 0 ? (
                                        timeSlots.map((slot, index) => (
                                            <option key={index} value={slot}>
                                                {slot}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">Aucune heure disponible</option>
                                    )}
                                </select>
                                <br />
                                <br />
                                <br />
                            </>
                        )}

                        <p className="warning-text">
                            *Ce site ne dispose pas encore de moyen de paiement en ligne, vous serez donc contraint de payer directement en allant chercher votre panier à la ferme du Fersac (26 Rue Mle du Vautenet 35270 Meillac).
                        </p>

                        <div className="btn-51">
                            <button type="submit">Valider</button>
                            <button type="button" onClick={() => setShowNewForm(false)}>Annuler</button>
                        </div>
                    </form>
                </div>
            </div>
        )}
        {showReservationForm && (
                    <div className="reservation-form">
                        <div className="form-container">
                            {renderCartSummary()}
                            <button className="confirm-btn" onClick={() => {
                                // Ajouter ici la logique pour confirmer la réservation
                                // Vous pourriez appeler une fonction pour traiter la réservation
                                handleOpenNewForm();
                            }}>
                                Confirmer la réservation
                            </button>
                            <button className="close-btn" onClick={handleCloseReservationForm}>
                                Annuler
                            </button>
                        </div>
                    </div>
                )}
                    {isAddingProduct && (
    <div className="product-upload">
        <form onSubmit={handleSubmitProduct}>
            <h2>{isEditingProduct ? 'Modifier le produit' : 'Ajouter un produit'}</h2>
            <input
                type="text"
                placeholder="Titre"
                value={newProduct.title}
                onChange={(e) => setNewProduct({ ...newProduct, title: e.target.value })}
                required
            />
            <input
                type="number"
                placeholder="Prix"
                value={newProduct.price}
                onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                required
            />
            <select
                value={newProduct.unit}
                onChange={(e) => setNewProduct({ ...newProduct, unit: e.target.value })}
            >
                <option value="kg">kg</option>
                <option value="pièce">pièce</option>
            </select>

            {newProduct.unit === "kg" && (
                <>
                    <input
                        type="number"
                        placeholder="Portion minimum (en grammes)"
                        value={newProduct.minPortion}
                        onChange={(e) => setNewProduct({ ...newProduct, minPortion: e.target.value })}
                        required
                    />
                    <input
                        type="number"
                        placeholder="Portion maximum (en grammes)"
                        value={newProduct.maxPortion}
                        onChange={(e) => setNewProduct({ ...newProduct, maxPortion: e.target.value })}
                        required
                    />
                </>
            )}

            {newProduct.unit === "pièce" && (
                <>
                    <input
                        type="number"
                        placeholder="Portion minimum (1)"
                        value={newProduct.minPortion} // Valeur par défaut 1
                        onChange={(e) => setNewProduct({ ...newProduct, minPortion: e.target.value })}
                        required
                    />
                    <input
                        type="number"
                        placeholder="Portion maximum"
                        value={newProduct.maxPortion}
                        onChange={(e) => setNewProduct({ ...newProduct, maxPortion: e.target.value })}
                        required
                    />
                </>
            )}

            <select
                value={newProduct.zone}
                onChange={(e) => setNewProduct({ ...newProduct, zone: e.target.value })}
                required
            >
                <option value="">Sélectionner une zone</option>
                {zones.map((zone, index) => (
                    <option key={index} value={zone.name}>{zone.name}</option>
                ))}
            </select>
            <select
                value={newProduct.subzone}
                onChange={(e) => setNewProduct({ ...newProduct, subzone: e.target.value })}
                required
            >
                <option value="">Sélectionner une sous-zone</option>
                {subzones.map((subzone, index) => (
                    <option key={index} value={subzone}>{subzone}</option>
                ))}
            </select>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => setSelectedImage(e.target.files[0])}
            />
            <div className="button-container">
                <button type="submit">{isEditingProduct ? 'Mettre à jour le produit' : 'Ajouter le produit'}</button>
                <button type="button" onClick={() => setIsAddingProduct(false)}>Fermer</button>
            </div>
            {error && <p className="error">{error}</p>}
        </form>
    </div>
)}
                </div>
                <Footer2 setShowMentionsLegales={toggleFooter} />
                </>
            )}
            
        
            </div>
            
            
        </>
    );
};

export default Commande;