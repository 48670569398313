import React from 'react';
import './MentionsLegales.css';
import enveloppe from './Image/enveloppe3.png';

const MentionsLegales = () => {
  return (
    <div className="mentions-container">
      <h1>Conditions générales</h1>
      <p className="standard">
        L’entreprise La Ferme du Fersac, dont le siège social est situé au <a href="https://maps.app.goo.gl/6tGXdtbLyaXfvywB9" target="_blank" rel="noopener noreferrer" className="map-link2">26 rue Mle du Vautenet à Meillac</a> (35270), est enregistrée sous le numéro de SIRET 929 666 576 00012. 
        <br/>
        Le site est accessible à l’adresse <a href="https://lafermedufersac.fr" target="_blank" rel="noopener noreferrer" className="link">lafermedufersac.fr</a>.
      </p>

      <h3>Hébergeur :</h3>
      <p className="liste">Firebase, une plateforme de Google LLC</p>
      <p className="liste">Siège social : 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis</p>
      
      <br/>

      <h3>Création / Réalisation :</h3>
      <p className="liste">
        Ce site internet a été réalisé par <a href="https://www.linkedin.com/in/youen-hamon-3538b42b2/" target="_blank" rel="noopener noreferrer" className="link">Youen HAMON</a>
      </p>
      <div className="contact-info">
        <img src={enveloppe} alt="Enveloppe" className="enveloppe-icon" />
        <a href="mailto:youenhamon@gmail.com" className="email-link">youenhamon@gmail.com</a>
      </div>

      <br/>

      <h3>Conditions d'utilisation :</h3>
      <p className="liste">
        En accédant à ce site, vous acceptez les présentes conditions d'utilisation. La Ferme du Fersac se réserve le droit de modifier ces conditions à tout moment.
      </p>

      <br/>

      <h3>Propriété intellectuelle :</h3>
      <p className="liste">
        Tous les contenus (textes, images, logo) présents sur ce site sont la propriété exclusive de La Ferme du Fersac, sauf mention contraire. Toute reproduction ou distribution sans autorisation préalable est interdite.
      </p>

      <br/>

      <h3>Politique de confidentialité :</h3>
      <p className="liste">
        La Ferme du Fersac respecte la confidentialité de vos données personnelles. Les informations collectées sont utilisées uniquement dans le cadre de la gestion des commandes et de l’abonnement à la newsletter. Aucune donnée personnelle n’est transmise à des tiers sans consentement préalable.
      </p>

      <br/>

      <h3>Responsabilité :</h3>
      <p className="liste">
        La Ferme du Fersac décline toute responsabilité pour des dommages directs ou indirects causés par l'utilisation du site. En cas de problème technique, contactez-nous à l'adresse email lafermedufersac@gmail.com.
      </p>

      <br/>

      <h3>Cookies :</h3>
      <p className="liste">
        Ce site utilise des cookies pour améliorer l’expérience utilisateur. En naviguant sur ce site, vous acceptez l’utilisation de cookies. Vous pouvez à tout moment désactiver les cookies via les paramètres de votre navigateur.
      </p>

      <br/>
      <br/>
    </div>
  );
};

export default MentionsLegales;