import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage'; // Importation du module de stockage
import { getDatabase } from "firebase/database"; // Importer Realtime Database
import { getFunctions, httpsCallable } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyD8XbVlLPLF5x0J1grk7ZiIjgxE-whXaWc",
  authDomain: "site-web-victor-v2.firebaseapp.com",
  projectId: "site-web-victor-v2",
  storageBucket: "site-web-victor-v2.appspot.com",
  messagingSenderId: "321011286083",
  appId: "1:321011286083:web:24eb57d96dc931a88194f6",
  measurementId: "G-NXEXL9NK16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Initialize Firebase Storage
const storage = getStorage(app); // Ajoutez cette ligne pour initialiser Firebase Storage

const database = getDatabase(app);

const functions = getFunctions(app);



export { auth, db, storage, database, functions }; // Exportez également `storage`