import React from 'react';
import './Footer.css';

const Footer = ({ setShowMentionsLegales, isRelative }) => {
  return (
    <div className={`footer ${isRelative ? "relative" : "fixed"}`}>
      La Ferme du Fersac © 2024 – Tous droits réservés - 
      <span
        className="footer-link"
        onClick={() => setShowMentionsLegales(true)}
      >
        &nbsp;CGV & Mentions légales
      </span>
    </div>
  );
};

export default Footer;