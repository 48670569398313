import React, { useState, useEffect } from 'react';
import './mon-compte.css';
import { auth } from './firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import Footer from './Footer';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useAuth } from './AuthContext';

const Compte = ({ hidden, toggleFooter }) => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState(""); 
    const [telephone, setTelephone] = useState(""); 
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const { user, setUser } = useAuth();

    const adminEmails = ["youenhamon@gmail.com", "victorleoleblanc@gmail.com"];

    // Ajoutez un état pour contrôler si l'inscription est en cours
    const [isRegisteringInProgress, setIsRegisteringInProgress] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
            // Ne pas exécuter la logique si l'inscription est en cours
            if (isRegisteringInProgress) return;

            if (firebaseUser) {
                const userDoc = await getDoc(doc(collection(db, "users"), firebaseUser.uid));
                const userData = userDoc.data();

                if (!firebaseUser.emailVerified) {
                    await signOut(auth);
                    setUser(null);
                    alert("Veuillez vérifier votre adresse e-mail pour vous connecter. Si vous n'avez pas reçu d'e-mail, il est possible que vous ayez fait une erreur dans l'adresse saisie lors de l'inscription.");
                } else {
                    setUser({
                        email: firebaseUser.email,
                        prenom: userData?.prenom || "Utilisateur",
                        isAdmin: userData?.isAdmin || false,
                    });
                }
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [setUser, isRegisteringInProgress]);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password.length < 6) {
            alert("Le mot de passe doit contenir au moins 6 caractères.");
            return;
        }
        if (password !== confirmPassword) {
            alert("Les mots de passe ne correspondent pas.");
            return;
        }
        setIsRegisteringInProgress(true); // Indique que l'inscription est en cours
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await updateProfile(user, { displayName: prenom });
    
            const isAdmin = adminEmails.includes(email);
    
            await setDoc(doc(collection(db, "users"), user.uid), {
                email: user.email,
                prenom: prenom,
                nom: nom,
                tel: telephone,
                isAdmin: isAdmin
            });
    
            await sendEmailVerification(user);
            alert("Un e-mail de vérification a été envoyé. Veuillez vérifier votre boîte de réception (y compris les courriers indésirables). Une fois vérifié, vous pourrez vous reconnecter.");
    
            await signOut(auth); // Déconnexion immédiate
    
            // Affiche le formulaire de connexion
            setIsRegistering(false); // Basculer vers le formulaire de connexion
    
            // Réinitialiser les champs du formulaire
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setPrenom("");
            setNom(""); 
            setTelephone("");
    
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                alert("Cette adresse e-mail est déjà utilisée.");
            } else {
                alert(error.message);
            }
        } finally {
            setIsRegisteringInProgress(false); // Réinitialise l'état après l'inscription
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
    
            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            const userData = userDoc.data();
    
            setUser({
                email: user.email,
                prenom: userData?.prenom || "Utilisateur",
                isAdmin: userData?.isAdmin || false,
            });
    
            setEmail("");
            setPassword("");
        } catch (error) {
            if (error.code === 'auth/user-not-found') {
                alert("Aucun compte trouvé avec cette adresse e-mail.");
            } else if (error.code === 'auth/wrong-password') {
                alert("Le mot de passe est incorrect.");
            } else {
                alert(error.message);
            }
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            alert(error.message);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, resetEmail);
            alert("E-mail de réinitialisation envoyé.");
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <>
            <div hidden={hidden} className="centered-container">
                {user ? (
                    <div>
                        <h2>Bonjour {user.prenom} !</h2>
                        {user.isAdmin && <p>Vous êtes l'administrateur</p>}
                        <button className="btn3" onClick={handleSignOut}>Se déconnecter</button>
                    </div>
                ) : (
                    <div>
                        {isRegistering ? (
                            <form className="test2-form" onSubmit={handleRegister}>
                                <h2>Inscription</h2>
                                <input
                                    type="text"
                                    className="input-field"
                                    placeholder="Prénom"
                                    value={prenom}
                                    onChange={(e) => setPrenom(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    className="input-field"
                                    placeholder="Nom de famille"
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                    required
                                />
                                <div className="phone-input-container">
                                <input
                                    type="tel"
                                    className="input-field"
                                    placeholder="Numéro de téléphone"
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                    pattern="\d*" // Accepte uniquement les chiffres
                                    required
                                />

                                {/* Tooltip */}
                                <div className="tooltip-text">
                                    Ce numéro de téléphone est uniquement utilisé pour des raisons de contact et ne sera pas utilisé à des fins commerciales.
                                </div>
                                </div>
                                <input
                                    type="email"
                                    className="input-field"
                                    placeholder="Adresse mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <input
                                    type="password"
                                    className="input-field"
                                    placeholder="Mot de passe"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <input
                                    type="password"
                                    className="input-field"
                                    placeholder="Confirmer le mot de passe"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <button className="btn" type="submit">S'inscrire</button>
                                <button className="btn" type="button" onClick={() => setIsRegistering(false)}>Déjà un compte ? Se connecter</button>
                            </form>
                        ) : showResetPassword ? (
                            <form className="test2-form" onSubmit={handlePasswordReset}>
                                <h2>Mot de passe oublié ?</h2>
                                <input
                                    type="email"
                                    className="input-field"
                                    placeholder="Adresse mail"
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                    required
                                />
                                <button className="btn2" type="submit">Réinitialiser le mot de passe</button>
                                <button className="btn2" type="button" onClick={() => setShowResetPassword(false)}>Retour à la connexion</button>
                            </form>
                        ) : (
                            <form className="test2-form" onSubmit={handleLogin}>
                                <h2>Connexion</h2>
                                <input
                                    type="email"
                                    className="input-field"
                                    placeholder="Adresse mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <input
                                    type="password"
                                    className="input-field"
                                    placeholder="Mot de passe"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button className="btn" type="submit">Se connecter</button>
                                <button className="btn" type="button" onClick={() => setIsRegistering(true)}>Pas de compte ? S'inscrire</button>
                                <button className="btn" type="button" onClick={() => setShowResetPassword(true)}>Mot de passe oublié ?</button>
                            </form>
                        )}
                    </div>
                )}
            </div>
            <Footer setShowMentionsLegales={toggleFooter} />
        </>
    );
};

export default Compte;