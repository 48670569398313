import React, { useState } from 'react';
import './header.css';
import logo from './Image/Logo.jpg';
import { useAuth } from "./AuthContext";
import { auth, functions } from './firebase';

const Header = ({ setShowActu, setShowCommande, setShowAccueil, setShowQuiSommesNous, setShowContact, setShowCompte }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useAuth();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const clickActu = () => {
    setShowActu();
    setMenuOpen(false);
  };
  const clickCommande = () => {
    setShowCommande();
    setMenuOpen(false);
  };
  const clickAccueil = () => {
    setShowAccueil();
    setMenuOpen(false);
  };
  const clickQuiSommesNous = () => {
    setShowQuiSommesNous();
    setMenuOpen(false);
  };
  const clickContact = () => {
    setShowContact();
    setMenuOpen(false);
  };
  const clickCompte = () => {
    setShowCompte();
    setMenuOpen(false);
  };
  
  return (
    <div className="container">
      <div className="information">
        <div className="u1">
          <div className="titre_header" onClick={clickAccueil}>
            ACCUEIL
          </div>
          <div className="titre_header" onClick={clickCommande}>
            COMMANDE
          </div>
          <div className="titre_header" onClick={clickActu}>
            NOS ACTUS
          </div>
          <div className="titre_header" onClick={clickQuiSommesNous}>
            QUI SOMMES NOUS
          </div>
          <div className="titre_header" onClick={clickContact}>
            CONTACT
          </div>
        </div>
        <div className="profil">
          <div className="titre_header2" onClick={clickAccueil}>
            <img src={logo} alt="Logo" />
          </div>
        </div>
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776;
        </div>
      </div>
      {menuOpen && (
        <div className="dropdown-menu">
          <div className="close-icon" onClick={toggleMenu}>
            ✕
          </div>
          <div className="titre_header" onClick={clickAccueil}>
            ACCUEIL
          </div>
          <div className="titre_header" onClick={clickCommande}>
            COMMANDE
          </div>
          <div className="titre_header" onClick={clickActu}>
            NOS ACTUS
          </div>
          <div className="titre_header" onClick={clickQuiSommesNous}>
            QUI SOMMES NOUS
          </div>
          <div className="titre_header" onClick={clickContact}>
            CONTACT
          </div>
          <div className="titre_header mobile-only" onClick={clickCompte}>
            MON COMPTE
          </div>
        </div>
      )}
      
      <div className="information2">
      {user ? (
          <div className="personne2" onClick={clickCompte}></div>
        ) : (
          <div className="personne" onClick={clickCompte}></div>
      )}
      </div>
    </div>
  );
};

export default Header; 