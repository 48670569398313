import React, { useState } from 'react';
import './App.css';
import Header from './header';
import Actu from './Actu';
import Commande from './Commande';
import Accueil from './Accueil';
import Footer from './Footer';
import MentionsLegales from './MentionsLegales';
import QuiSommesNous from './QuiSommesNous';
import Contact from './Contact';
import Compte from './mon-compte';
import { AuthProvider } from './AuthContext'; // Importer AuthProvider

function App() {
  const [currentPage, setCurrentPage] = useState('accueil');
  const [showMentionsLegales, setShowMentionsLegales] = useState(false);

  const renderCurrentPage = () => {
    switch (currentPage) {
      case 'actu':
        return <Actu hidden={false} toggleFooter={handleSetShowMentionsLegales} />;
      case 'commande':
        return <Commande hidden={false} toggleFooter={handleSetShowMentionsLegales} />;
      case 'accueil':
        return <Accueil hidden={false} toggleFooter={handleSetShowMentionsLegales} />;
      case 'quisommesnous':
        return <QuiSommesNous hidden={false} toggleFooter={handleSetShowMentionsLegales} />;
      case 'contact':
        return <Contact hidden={false} toggleFooter={handleSetShowMentionsLegales} />;
      case 'compte':
        return <Compte hidden={false} toggleFooter={handleSetShowMentionsLegales} />;
      default:
        return null;
    }
  };

  const handleSetShowMentionsLegales = (value) => {
    setShowMentionsLegales(value);
    if (value) {
      setCurrentPage(''); // Clear the current page when showing Mentions Legales
    }
  };

  return (
    <AuthProvider>
      <div className="App">
        <Header
          setShowActu={() => { 
            setCurrentPage('actu'); 
            setShowMentionsLegales(false); 
          }}
          setShowCommande={() => { 
            setCurrentPage('commande'); 
            setShowMentionsLegales(false); 
          }}
          setShowAccueil={() => { 
            setCurrentPage('accueil'); 
            setShowMentionsLegales(false); 
          }}
          setShowQuiSommesNous={() => {
            setCurrentPage('quisommesnous');
            setShowMentionsLegales(false); 
          }}
          setShowContact={() => {
            setCurrentPage('contact');
            setShowMentionsLegales(false); 
          }}
          setShowCompte={() => {
            setCurrentPage('compte');
            setShowMentionsLegales(false); 
          }}
        />

        {showMentionsLegales ? (
          <MentionsLegales setShowMentionsLegales={handleSetShowMentionsLegales} />
        ) : (
          renderCurrentPage()
        )}
      </div>
    </AuthProvider>
  );
}

export default App;