import { db, storage } from './firebase'; // Assurez-vous que `storage` est bien importé
import { collection, onSnapshot, query, orderBy, setDoc, getDoc, doc, updateDoc, arrayUnion, addDoc, Timestamp, deleteDoc, getDocs, where, arrayRemove, writeBatch } from 'firebase/firestore';
import { ref, uploadString, getDownloadURL, deleteObject, uploadBytes } from 'firebase/storage';
import CryptoJS from 'crypto-js';
import { useAuth } from "./AuthContext";
import { auth } from './firebase';
import { updateEmail as updateAuthEmail } from 'firebase/auth';

// Fonction pour écouter les mises à jour en temps réel
export const listenActu = (onActuUpdated) => {
  const actuQuery = query(collection(db, 'Actu'), orderBy('publication', 'desc'));

  return onSnapshot(actuQuery, (snapshot) => {
      const ActuList = snapshot.docs.map(doc => {
          const data = doc.data();
          data.publication = data.publication.toDate(); // Convertir le Timestamp en Date
          return { id: doc.id, ...data };
      });
      onActuUpdated(ActuList);
  });
};

export const listenAccueil = (onAccueilUpdated) => {
  return onSnapshot(collection(db, 'Accueil'), (snapshot) => {
      const AccueilList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      onAccueilUpdated(AccueilList);
  });
};

// Fonction pour mettre à jour l'intro
export const updateIntro = async (newIntro) => {
  const docRef = doc(db, 'Accueil', 'introDoc');
  await setDoc(docRef, { intro: newIntro }, { merge: true });
};

const calculateHash = (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
          const wordArray = CryptoJS.lib.WordArray.create(reader.result);
          const hash = CryptoJS.SHA256(wordArray).toString(CryptoJS.enc.Hex);
          resolve(hash);
      };
      reader.onerror = (error) => {
          reject(error);
      };
      reader.readAsArrayBuffer(file);
  });
};

// Fonction pour ajouter une image
export const addImage = async (imageFile, imageTitle) => {
  try {
      // Calculer le hash de l'image
      const imageHash = await calculateHash(imageFile);

      const docRef = doc(db, 'Accueil', 'introDoc');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
          let data = docSnap.data();
          let images = data.image || [];

          // Vérifier si le hash de l'image existe déjà
          const imageExists = images.some(image => image.hash === imageHash);

          if (imageExists) {
              throw new Error("L'image existe déjà dans Firestore.");
          }
      }

      // Lire le fichier en tant qu'ArrayBuffer
      const fileArrayBuffer = await imageFile.arrayBuffer();

      // Upload du fichier
      const imageRef = ref(storage, `images/${imageFile.name}`);
      const snapshot = await uploadBytes(imageRef, new Uint8Array(fileArrayBuffer));

      // Obtenir l'URL de téléchargement
      const imageUrl = await getDownloadURL(snapshot.ref);

      // Ajouter l'URL et le titre à Firestore
      await updateDoc(docRef, {
          image: arrayUnion({
              url: imageUrl,
              title: imageTitle,
              hash: imageHash // Stocker également le hash
          })
      });
  } catch (error) {
      console.error("Erreur lors de l'ajout de l'image :", error);
      throw error;
  }
};

export const deleteImage = async (imageUrl, imageTitle) => {
  const imageRef = ref(storage, imageUrl);

  try {
    // Supprimer l'image de Firebase Storage
    await deleteObject(imageRef);

    // Supprimer l'URL de l'image et son titre dans Firestore
    const docRef = doc(db, 'Accueil', 'introDoc');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let data = docSnap.data();
      let images = data.image || [];

      // Filtrer l'objet image à supprimer
      images = images.filter(image => image.url !== imageUrl || image.title !== imageTitle);

      // Mettre à jour le document Firestore
      await updateDoc(docRef, { image: images });
    } else {
      console.error('Le document Firestore spécifié n\'existe pas.');
    }
  } catch (err) {
    console.error(`Erreur lors de la suppression de l'image : ${err.message}`);
    throw new Error(`Erreur lors de la suppression de l'image : ${err.message}`);
  }
};


export const uploadImage = async (file) => {
  const storageRef = ref(storage, `images/${file.name}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

// Fonction pour ajouter une nouvelle actualité
export const addActu = async (actu) => {
  await addDoc(collection(db, "Actu"), {
      Title: actu.title,
      body: actu.body,
      color: actu.color,
      image: actu.image,
      publication: Timestamp.fromDate(new Date(actu.publication)) // Assurez-vous que la date est convertie en Timestamp
  });
};

export const deleteActu = async (id) => {
  await deleteDoc(doc(db, "Actu", id));
};

export const updateActu = async (id, updatedData) => {
    try {
        const actuDocRef = doc(collection(db, 'Actu'), id);
        await updateDoc(actuDocRef, updatedData);
        console.log(`Actualité avec ID ${id} mise à jour avec succès`);
    } catch (err) {
        console.error(`Erreur lors de la mise à jour de l'actualité avec ID ${id}:`, err);
        throw new Error(`Erreur lors de la mise à jour de l'actualité: ${err.message}`);
    }
};


const subscribersCollection = collection(db, "subscribers");

export const addSubscriber = async (email) => {
    // Vérifier si l'adresse e-mail existe déjà
    const q = query(subscribersCollection, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        throw new Error("Cette adresse e-mail est déjà inscrite à la newsletter.");
    }

    // Ajouter le nouvel abonné
    const subscriberDoc = {
        email: email,
        subscribedAt: Timestamp.fromDate(new Date()),
    };
    await addDoc(subscribersCollection, subscriberDoc);
};

export const getSubscribers = async () => {
    const snapshot = await getDocs(subscribersCollection);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const deleteSubscriber = async (id) => {
    const subscriberRef = doc(subscribersCollection, id);
    await deleteDoc(subscriberRef);
};

export const addProduct = async (product) => {
  try {
      const docRef = collection(db, 'commande');
      await addDoc(docRef, {
          title: product.title,
          price: parseFloat(product.price),
          unit: product.unit,
          minPortion: parseInt(product.minPortion, 10),
          maxPortion: parseInt(product.maxPortion, 10),
          zone: product.zone,
          subzone: product.subzone,
          image: product.image,
          available: true
      });
  } catch (error) {
      console.error("Erreur lors de l'ajout du produit :", error);
      throw error;
  }
};

export const updateProduct = async (id, updatedProduct) => {
    try {
        const productRef = doc(db, 'commande', id);

        // Mettre à jour l'available en fonction de maxPortion
        const availabilityStatus = updatedProduct.maxPortion < updatedProduct.minPortion ? false : updatedProduct.available;

        await updateDoc(productRef, {
            title: updatedProduct.title,
            price: parseFloat(updatedProduct.price),
            unit: updatedProduct.unit,
            minPortion: parseInt(updatedProduct.minPortion, 10),
            maxPortion: parseInt(updatedProduct.maxPortion, 10),
            zone: updatedProduct.zone,
            subzone: updatedProduct.subzone,
            image: updatedProduct.image,
            available: availabilityStatus
        });
    } catch (error) {
        console.error("Erreur lors de la mise à jour du produit :", error);
        throw error;
    }
};

export const deleteProduct = async (productId) => {
    try {
        const productDocRef = doc(db, 'commande', productId);
        await deleteDoc(productDocRef);
        console.log("Produit supprimé avec succès");
    } catch (error) {
        console.error("Erreur lors de la suppression du produit :", error);
        throw new Error(`Erreur lors de la suppression du produit : ${error.message}`);
    }
};

// Fonction pour télécharger une image
export const uploadProductImage = async (file) => {
  try {
      const storageRef = ref(storage, `products/${file.name}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      return imageUrl;
  } catch (error) {
      console.error("Erreur lors du téléchargement de l'image :", error);
      throw error;
  }
};

export const getProducts = async () => {
  try {
      const productsCollection = collection(db, "commande");
      const productsSnapshot = await getDocs(productsCollection);
      const productsList = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return productsList;
  } catch (error) {
      console.error("Error fetching products: ", error);
      return [];
  }
};


export const updateProductStock = async (productId, quantityChange) => {
  const productRef = doc(db, 'commande', productId);

  try {
      const docSnap = await getDoc(productRef);
      if (docSnap.exists()) {
          const product = docSnap.data();
          const newMaxPortion = product.maxPortion + quantityChange;

          if (newMaxPortion <= 0) {
              await updateDoc(productRef, {
                  maxPortion: 0,
                  available: false
              });
          } else {
              await updateDoc(productRef, {
                  maxPortion: newMaxPortion
              });
          }
      }
  } catch (error) {
      console.error("Erreur de mise à jour du stock :", error);
  }
};

export const updateStock = async (productId, quantityChange) => {
    try {
        const productRef = doc(db, 'commande', productId);
        const docSnap = await getDoc(productRef);

        if (docSnap.exists()) {
            const product = docSnap.data();
            const currentMaxPortion = Number(product.maxPortion);
            const newMaxPortion = currentMaxPortion + quantityChange;

            // Log values for debugging
            console.log(`Product ID: ${productId}`);
            console.log(`Current Max Portion: ${currentMaxPortion}`);
            console.log(`Quantity Change: ${quantityChange}`);
            console.log(`New Max Portion: ${newMaxPortion}`);

            // Update stock and availability based on new max portion
            if (newMaxPortion <= 0 || newMaxPortion < product.minPortion) {
                await updateDoc(productRef, {
                    maxPortion: newMaxPortion,
                    available: false
                });
            } else {
                await updateDoc(productRef, {
                    maxPortion: newMaxPortion,
                    available: true // Réactive le produit si le stock est suffisant
                });
            }

            console.log("Stock updated successfully");
        } else {
            console.error("Document does not exist.");
        }
    } catch (error) {
        console.error("Erreur lors de la mise à jour du stock :", error);
    }
};

//const getCurrentUser = () => {
    //const auth = getAuth();
    //return auth.currentUser;
//};
//const user = getCurrentUser();

export const saveCartToFirebase = async (cart, userId) => {
    try {
        // Assurez-vous que userId est défini
        if (!userId) {
            throw new Error("userId is undefined");
        }

        // Référence du document `cart` de l'utilisateur dans Firestore
        const cartRef = doc(db, "users", userId, "cart", "cartData");

        // Données à sauvegarder
        const cartData = {
            products: cart.map(item => ({
                name: item.title,
                cartPortion: item.quantity, // Utiliser directement la quantité
                unit: item.unit,
                price: item.quantity * item.price // Calculer le prix total basé sur la quantité
            }))
        };

        // Enregistrement des données dans Firestore
        await setDoc(cartRef, cartData);
        console.log("Panier sauvegardé avec succès sur Firebase !");
    } catch (error) {
        console.error("Erreur lors de la sauvegarde du panier sur Firebase : ", error);
    }
};


const getProductById = async (productId) => {
    try {
      const productRef = doc(db, "commande", productId);
      const productSnap = await getDoc(productRef);
      return productSnap.exists() ? productSnap.data() : null;
    } catch (error) {
      console.error("Erreur lors de la récupération du produit :", error);
      return null;
    }
  };
  
  export const addToCart = async (userId, productId) => {
    try {
        // Récupérer les détails du produit
        const product = await getProductById(productId);
        if (!product) {
            throw new Error('Produit non trouvé');
        }

        console.log("Détails du produit récupérés :", product);

        // Calculer le prix par portion minimale si l'unité est kg
        let pricePerMinPortion = product.price;
        if (product.unit === 'kg') {
            pricePerMinPortion = (product.price / 1000) * product.minPortion;
        }

        console.log("Prix par portion minimale :", pricePerMinPortion);

        // Référence de la collection `cart` de l'utilisateur dans Firestore
        const cartCollectionRef = collection(db, 'users', userId, 'cart');

        // Créer un nouveau document pour le produit dans le panier avec un ID généré automatiquement
        const newCartItemRef = await addDoc(cartCollectionRef, {
            productId, // Ajoutez l'ID du produit ici
            name: product.title,
            cartPortion: product.unit === 'kg' ? Number(product.minPortion) : 1,
            unit: product.unit,
            image: product.image,
            price: product.unit === 'kg'
                ? (product.unit === 'kg' ? ((Number(product.minPortion) / 1000) * product.price).toFixed(2) : product.price.toFixed(2))
                : product.price.toFixed(2)
        });

        console.log("Produit ajouté au panier avec ID :", newCartItemRef.id);
        
    } catch (error) {
        console.error("Erreur lors de l'ajout du produit au panier :", error);
        throw error;
    }
};


export const getCartFromFirebase = async (userId) => {
    try {
        // Référence de la collection `cart` de l'utilisateur dans Firestore
        const cartCollectionRef = collection(db, 'users', userId, 'cart');
        const cartSnapshot = await getDocs(cartCollectionRef);

        // Convertir les documents en un tableau de produits
        const products = cartSnapshot.docs.map(doc => ({
            id: doc.id, // Inclure l'ID du document
            ...doc.data() // Inclure les données du document
        }));

        return { products };
    } catch (error) {
        console.error("Erreur lors de la récupération du panier depuis Firebase :", error);
        return { products: [] }; // Retourne un panier vide en cas d'erreur
    }
};

export const increaseCartItem = async (userId, cartItemId) => {
    try {
        if (!userId || !cartItemId) {
            throw new Error("L'ID de l'utilisateur ou l'ID de l'article du panier est manquant.");
        }

        // Référence du produit dans Firestore
        const cartItemRef = doc(db, 'users', userId, 'cart', cartItemId);
        const cartItemSnap = await getDoc(cartItemRef);

        if (!cartItemSnap.exists()) {
            throw new Error('Article du panier non trouvé');
        }

        const cartItem = cartItemSnap.data();
        const productId = cartItem.productId; // L'ID du produit est maintenant stocké dans le panier

        if (!productId) {
            throw new Error("L'ID du produit est manquant dans l'article du panier.");
        }

        // Récupérer les détails du produit
        const productRef = doc(db, 'commande', productId);
        const productSnap = await getDoc(productRef);

        if (!productSnap.exists()) {
            throw new Error('Produit non trouvé');
        }

        const product = productSnap.data();
        const minPortion = Number(product.minPortion);
        const currentCartPortion = Number(cartItem.cartPortion);

        if (isNaN(minPortion) || isNaN(currentCartPortion)) {
            throw new Error("Portion minimale ou portion du panier est invalide.");
        }

        // Vérifiez si le stock est suffisant
        const newMaxPortion = Number(product.maxPortion) - minPortion;
        if (newMaxPortion < 0) {
            throw new Error('Stock insuffisant');
        }

        // Mettre à jour le stock
        await updateStock(productId, -minPortion);

        // Mettre à jour le panier
        const newCartPortion = currentCartPortion + minPortion;
        const newTotalPrice = (product.unit === 'kg')
            ? ((newCartPortion / 1000) * product.price).toFixed(2)
            : (product.price * newCartPortion).toFixed(2);

        await updateDoc(cartItemRef, {
            cartPortion: newCartPortion,
            price: parseFloat(newTotalPrice)
        });

        console.log("Panier mis à jour avec succès !");
    } catch (error) {
        console.error("Erreur lors de l'augmentation de la portion du panier :", error);
        throw error;
    }
};

export const removeFromCart = async (userId, cartItemId) => {
    try {
        if (!userId || !cartItemId) {
            throw new Error("L'ID de l'utilisateur ou l'ID de l'article du panier est manquant.");
        }

        // Référence de l'article du panier dans Firestore
        const cartItemRef = doc(db, 'users', userId, 'cart', cartItemId);
        const cartItemSnap = await getDoc(cartItemRef);

        if (!cartItemSnap.exists()) {
            throw new Error('Article du panier non trouvé');
        }

        const cartItem = cartItemSnap.data();
        const productId = cartItem.productId; // Assurez-vous que l'ID du produit est stocké dans l'article du panier

        if (!productId) {
            throw new Error("L'ID du produit est manquant dans l'article du panier.");
        }

        // Récupérer les détails du produit
        const productRef = doc(db, 'commande', productId);
        const productSnap = await getDoc(productRef);

        if (!productSnap.exists()) {
            throw new Error('Produit non trouvé');
        }

        const product = productSnap.data();
        const minPortion = Number(product.minPortion);
        const currentCartPortion = Number(cartItem.cartPortion);

        if (isNaN(minPortion) || isNaN(currentCartPortion)) {
            throw new Error("Portion minimale ou portion du panier est invalide.");
        }

        // Mettre à jour le stock
        const newMaxPortion = Number(product.maxPortion) + currentCartPortion;
        await updateDoc(productRef, {
            maxPortion: newMaxPortion,
            available: newMaxPortion > 0 // Assurez-vous que le produit reste disponible si le stock est positif
        });

        // Supprimer l'article du panier
        await deleteDoc(cartItemRef);

        console.log("Produit supprimé du panier avec succès !");
    } catch (error) {
        console.error("Erreur lors de la suppression du produit du panier :", error);
        throw error;
    }
};

export const getProductDetails = async (productId) => {
    const productRef = doc(db, 'commande', productId);
    const productDoc = await getDoc(productRef);

    if (!productDoc.exists()) {
        throw new Error('Produit non trouvé');
    }

    return productDoc.data();
};

export const subscribeToProductDetails = (productId, callback) => {
    const productRef = doc(db, 'commande', productId);

    const unsubscribe = onSnapshot(productRef, (doc) => {
        if (doc.exists()) {
            const data = doc.data();
            console.log(`Product details for ${productId}:`, data);

            // Assurez-vous que minPortion et maxPortion sont présents et valides
            const minPortion = data.minPortion !== undefined ? data.minPortion : 0;
            const maxPortion = data.maxPortion !== undefined ? data.maxPortion : 0;
            
            if (minPortion === 0 || maxPortion === 0) {
                console.warn(`Min or Max Portion is zero or undefined for ${productId}. Data:`, data);
            }

            callback({
                ...data,
                minPortion,
                maxPortion
            });
        } else {
            console.error(`Produit non trouvé pour l'ID ${productId}`);
            callback(null);
        }
    }, (error) => {
        console.error('Erreur lors de la récupération des détails du produit :', error);
        callback(null);
    });

    return unsubscribe;
};


export const decreaseCartItem = async (userId, cartItemId) => {
    try {
        if (!userId || !cartItemId) {
            throw new Error("L'ID de l'utilisateur ou l'ID de l'article du panier est manquant.");
        }

        // Référence de l'article du panier dans Firestore
        const cartItemRef = doc(db, 'users', userId, 'cart', cartItemId);
        const cartItemSnap = await getDoc(cartItemRef);

        if (!cartItemSnap.exists()) {
            throw new Error('Article du panier non trouvé');
        }

        const cartItem = cartItemSnap.data();
        const productId = cartItem.productId;

        if (!productId) {
            throw new Error("L'ID du produit est manquant dans l'article du panier.");
        }

        // Récupérer les détails du produit
        const productRef = doc(db, 'commande', productId);
        const productSnap = await getDoc(productRef);

        if (!productSnap.exists()) {
            throw new Error('Produit non trouvé');
        }

        const product = productSnap.data();
        const minPortion = Number(product.minPortion);
        const currentCartPortion = Number(cartItem.cartPortion);

        if (isNaN(minPortion) || isNaN(currentCartPortion)) {
            throw new Error("Portion minimale ou portion du panier est invalide.");
        }

        // Calculer la nouvelle portion dans le panier
        const newCartPortion = currentCartPortion - minPortion;

        if (newCartPortion < minPortion) {
            // Si la nouvelle portion est inférieure à la portion minimale, on retire l'article du panier
            return 'remove';
        }

        // Mettre à jour le stock (on augmente le stock car on diminue la portion dans le panier)
        await updateStock(productId, minPortion);

        // Calculer le nouveau prix total
        const newTotalPrice = (product.unit === 'kg')
            ? ((newCartPortion / 1000) * product.price).toFixed(2)
            : (product.price * newCartPortion).toFixed(2);

        // Mettre à jour l'article du panier dans Firestore
        await updateDoc(cartItemRef, {
            cartPortion: newCartPortion,
            price: parseFloat(newTotalPrice)
        });

        console.log("Panier mis à jour avec succès !");
        return 'updated'; // Indiquer que l'item a été mis à jour
    } catch (error) {
        console.error("Erreur lors de la diminution de la portion du panier :", error);
        throw error;
    }
};

export const startTimer = async (userId) => {
    const timerRef = doc(db, 'users', userId, 'Timer', 'TimerId');
    const now = Date.now();
    await setDoc(timerRef, { startTime: now });
    console.log('Timer démarré:', new Date(now).toLocaleString());
};

export const getTimer = async (userId) => {
    const timerRef = doc(db, 'users', userId, 'Timer', 'TimerId');
    const timerSnap = await getDoc(timerRef);
    return timerSnap.exists() ? timerSnap.data() : null;
};

export const resetTimer = async (userId) => {
    try {
        const timerRef = doc(db, 'users', userId, 'Timer', 'TimerId');
        await deleteDoc(timerRef);
        console.log('Timer supprimé.');
    } catch (error) {
        console.error('Erreur lors de la suppression du timer :', error);
    }
};

export const checkAndHandleTimerExpiration = async (userId) => {
    const timerData = await getTimer(userId);
    if (!timerData) return; // Pas de timer, donc rien à faire

    const now = Date.now();
    const timeElapsed = now - timerData.startTime;

    if (timeElapsed >= 3600000) { // 5 minutes en millisecondes
        console.log('Le timer a expiré. Suppression des produits du panier.');

        const cartCollectionRef = collection(db, 'users', userId, 'cart');
        const cartItems = await getDocs(cartCollectionRef);

        for (const item of cartItems.docs) {
            await deleteDoc(item.ref);
        }

        await resetTimer(userId); // Reset le timer après la suppression
    }
};

export const handleReservationSubmit = async (userId, formData, cart, setShowNewForm, setCart) => {
    if (!userId) {
        alert("Vous devez être connecté pour faire une réservation.");
        return;
    }

    try {
        // Récupérer les données de l'utilisateur
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            throw new Error('Utilisateur non trouvé');
        }

        const userData = userDoc.data();
        const userName = `${userData.nom} ${userData.prenom}`;
        const userTel = userData.tel;

        // Construire les données du panier
        const cartData = cart.map(item => ({
            nom: item.name,
            quantité: item.cartPortion,
            unité: item.unit,
            prix: item.price,
            productId: item.productId,
        }));

        // Enregistrer la réservation dans Firestore et obtenir la référence du document
        const reservationRef = await addDoc(collection(db, 'réservation'), {
            date: formData.date,
            heure: formData.time,
            nom: userData.nom,
            prenom: userData.prenom,
            tel: userTel,
            produits: cartData,
        });

        // Supprimer le panier et le timer
        await clearUserCartAndTimer(userId);

        alert("Réservation enregistrée avec succès !");
        setShowNewForm(false);
        setCart([]); // Vider le panier localement

        // Retourner l'ID de la réservation pour l'utiliser dans generatePDF
        return reservationRef.id;

    } catch (error) {
        console.error("Erreur lors de la validation de la réservation :", error);
        alert("Une erreur s'est produite lors de la validation de la réservation.", error);
    }
};

// Fonction pour supprimer les sous-collections cart et Timer
const clearUserCartAndTimer = async (userId) => {
    const cartRef = collection(db, 'users', userId, 'cart');
    const timerRef = doc(db, 'users', userId, 'Timer', 'TimerId');

    const batch = writeBatch(db);

    try {
        // Supprimer les items dans la sous-collection cart
        const cartSnapshot = await getDocs(cartRef);
        cartSnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Supprimer le timer
        batch.delete(timerRef);

        await batch.commit();
        console.log("Panier et timer supprimés avec succès.");
    } catch (error) {
        console.error("Erreur lors de la suppression du panier et du timer :", error);
        throw new Error("Erreur lors de la suppression des données.");
    }
};

export const getReservationData = async (reservationId) => {
    try {
        if (typeof reservationId !== 'string') {
            throw new Error('L\'ID de réservation doit être une chaîne');
        }

        const reservationDoc = await getDoc(doc(db, 'réservation', reservationId));
        if (!reservationDoc.exists()) {
            throw new Error('Réservation non trouvée');
        }
        return reservationDoc.data();
    } catch (error) {
        console.error('Erreur lors de la récupération des données de réservation:', error);
        throw error;
    }
};


export const saveAdminAvailability = async (availability) => {
    try {
        await setDoc(doc(db, "admin", "availability"), { availability });
    } catch (error) {
        console.error("Erreur lors de la sauvegarde des disponibilités :", error);
    }
};

// Récupérer les disponibilités de l'administrateur
export const getAdminAvailability = async () => {
    try {
        const docSnap = await getDoc(doc(db, "admin", "availability"));
        if (docSnap.exists()) {
            return docSnap.data().availability;
        } else {
            console.log("Aucune disponibilité trouvée");
            return null;
        }
    } catch (error) {
        console.error("Erreur lors de la récupération des disponibilités :", error);
    }
};

export const getAllReservations = async () => {
    try {
        const reservationsCollection = collection(db, 'réservation');
        const reservationSnapshot = await getDocs(reservationsCollection);
        const reservations = reservationSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return reservations;
    } catch (error) {
        console.error('Erreur lors de la récupération des réservations:', error);
        throw error;
    }
};

export const updateCartItemPortion = async (userId, cartItemId, newPortion) => {
    const cartRef = doc(db, 'users', userId, 'cart', cartItemId);
    await updateDoc(cartRef, {
        cartPortion: newPortion
    });
};

export const updateParagraph1 = async (docId, newParagraph1) => {
    const docRef = doc(db, 'quiSommesNous', docId);
    await updateDoc(docRef, {
        paragraph1: newParagraph1
    });
};

// Mettre à jour le deuxième paragraphe
export const updateParagraph2 = async (docId, newParagraph2) => {
    const docRef = doc(db, 'quiSommesNous', docId);
    await updateDoc(docRef, {
        paragraph2: newParagraph2
    });
};

// Fonction pour écouter les changements en temps réel (si nécessaire)
export const listenQuiSommesNous = (onDataUpdate) => {
    const collRef = collection(db, 'quiSommesNous');

    // Écouter les changements dans la collection
    const unsubscribe = onSnapshot(collRef, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
                const doc = change.doc;
                onDataUpdate({
                    id: doc.id,
                    paragraph1: doc.data().paragraph1 || '',
                    paragraph2: doc.data().paragraph2 || ''
                });
            }
        });
    });

    return unsubscribe; // Retourner une fonction pour désabonner
};

export const getContactInfo = async () => {
    const contactRef = collection(db, 'contact');
    const snapshot = await getDoc(contactRef);
    if (snapshot.exists()) {
        return snapshot.data();
    } else {
        console.log('No such document!');
        return null;
    }
};

// Fonction pour écouter les changements en temps réel
export const listenContactInfo = (onDataUpdate) => {
    const contactRef = collection(db, 'contact');
    
    return onSnapshot(contactRef, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' || change.type === 'modified') {
                const doc = change.doc;
                onDataUpdate({
                    id: doc.id,
                    mail: doc.data().mail || '',
                    num: doc.data().num || '',
                    horaires: doc.data().horaires || ''
                });
            }
        });
    });
};

// Fonction pour mettre à jour les informations de contact
export const updateContactInfo = async (docId, updatedData) => {
    const docRef = doc(db, 'contact', docId);
    await updateDoc(docRef, updatedData);
};

export const updateImageVisibility = async (isVisible) => {
    const docRef = doc(db, 'Accueil', 'introDoc');
    await updateDoc(docRef, {
        'btn-image': isVisible
    });
};

export const getImageVisibility = async () => {
    const docRef = doc(db, 'Accueil', 'introDoc');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data()['btn-image'];
    } else {
        console.error("Le document n'existe pas!");
        return false;
    }
};