import React, { useState, useEffect } from "react";
import './Contact.css';
import Footer from './Footer';
import Loader from "./Loader";
import { useAuth } from "./AuthContext";
import { listenContactInfo, updateContactInfo } from './firestore';

const adminEmails = ["youenhamon@gmail.com", "victorleoleblanc@gmail.com"];

const Contact = ({ hidden, toggleFooter }) => {
    const [contactInfo, setContactInfo] = useState({ mail: "", num: "", horaires: "" });
    const [isEditing, setIsEditing] = useState(false);
    const [newMail, setNewMail] = useState("");
    const [newNum, setNewNum] = useState("");
    const [newHoraires, setNewHoraires] = useState("");
    const { user } = useAuth();
    const isAdmin = user && adminEmails.includes(user.email);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = listenContactInfo((data) => {
            setContactInfo(data);
            setNewMail(data.mail);
            setNewNum(data.num);
            setNewHoraires(data.horaires);
            setIsLoading(false); // Arrête le loader une fois que les données sont chargées
        });

        return () => unsubscribe();
    }, []);

    const handleUpdateContact = async (e) => {
        e.preventDefault();
        try {
            await updateContactInfo(contactInfo.id, {
                mail: newMail,
                num: newNum,
                horaires: newHoraires
            });
            setIsEditing(false); // Cacher le formulaire après la mise à jour
        } catch (error) {
            console.error('Erreur lors de la mise à jour des informations de contact:', error);
        }
    };

    return (
        <>
            <div hidden={hidden}>
                <h1>Contactez-nous</h1>
                {isLoading ? (
                        <Loader />
                        ) : (
                            <>
                                <p>Pour toute question, demande de renseignement ou simplement pour dire bonjour, n'hésitez pas à nous contacter. Nous serons ravis de vous répondre.</p>
                                <p>
                                    <span style={{ color: '#2A4C21' }}> <strong>Email :</strong></span> {contactInfo.mail}<br />
                                    <span style={{ color: '#2A4C21' }}> <strong>Téléphone :</strong></span> {contactInfo.num}<br />
                                    <span style={{ color: '#2A4C21' }}> <strong>Facebook :</strong></span> <a href="https://www.facebook.com/profile.php?id=61561012007869" target="_blank" rel="noopener noreferrer" className="map-link">Ferme du Fersac</a>
                                </p>
                                <p>Nous serions aussi ravis de vous accueillir à la ferme.</p>
                                <p>
                                    <span style={{ color: '#2A4C21' }}> <strong>Adresse :</strong></span> <a href="https://maps.app.goo.gl/6tGXdtbLyaXfvywB9" target="_blank" rel="noopener noreferrer" className="map-link">26 Rue Mle du Vautenet 35270 Meillac</a>
                                </p>
                                <p>
                                    <span style={{ color: '#2A4C21' }}>
                                        <strong>Voici nos horaires d'ouvertures :</strong>
                                    </span>
                                    <span
                                        dangerouslySetInnerHTML={{
                                        __html: contactInfo.horaires.replace(/\n/g, "<br/>"),
                                    }}
                                    />
                                </p>
                                {isAdmin && (
                                    <button className="button-info" onClick={() => setIsEditing(true)}>Modifier les informations de contact</button>
                                )}
                                <Footer setShowMentionsLegales={toggleFooter} />
                            </>
            )}  
            </div>

            {isEditing && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <form onSubmit={handleUpdateContact}>
                            <label>Email :</label>
                            <input type="email" value={newMail} onChange={(e) => setNewMail(e.target.value)} />

                            <label>Téléphone :</label>
                            <input type="text" value={newNum} onChange={(e) => setNewNum(e.target.value)} />

                            <label>Horaires d'ouverture :</label>
                            <textarea 
                                
                                value={newHoraires} 
                                onChange={(e) => setNewHoraires(e.target.value)} 
                                placeholder="Enter HTML formatted text"
                            />

                            <button type="submit">Mettre à jour</button>
                            <button type="button" onClick={() => setIsEditing(false)}>Annuler</button>
                        </form>
                    </div>
                </div>
            )}

            
        </>
    );
}

export default Contact;