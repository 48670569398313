import React from 'react';
import './Footer2.css';

const Footer2 = ({ setShowMentionsLegales }) => {
  return (
    <div className="footer2">
      La Ferme du Fersac © 2024 – Tous droits réservés - 
      <span
        className="footer2-link"
        onClick={() => setShowMentionsLegales(true)}
      >
        &nbsp;CGV & Mentions légales
      </span>
    </div>
  );
};

export default Footer2;