import React, { useState, useEffect } from "react";
import './QuiSommesNous.css';
import Photovic from './Image/Image-Victor.jpg';
import Footer from './Footer';
import Loader from "./Loader";
import { updateParagraph1, updateParagraph2, listenQuiSommesNous } from './firestore'; // Importer les fonctions
import { useAuth } from "./AuthContext"; // Importer le contexte d'authentification

const adminEmails = ["youenhamon@gmail.com", "victorleoleblanc@gmail.com"];

const QuiSommesNous = ({ hidden, toggleFooter }) => {
    const [paragraphs, setParagraphs] = useState({
        id: '',
        paragraph1: '',
        paragraph2: ''
    });
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [newParagraph1, setNewParagraph1] = useState("");
    const [newParagraph2, setNewParagraph2] = useState("");
    const { user } = useAuth(); // Utiliser le contexte pour obtenir l'utilisateur actuel
    const isAdmin = user && adminEmails.includes(user.email);
    const [showHelp, setShowHelp] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => { 
        const unsubscribe = listenQuiSommesNous((data) => {
            setParagraphs(data);
            setNewParagraph1(data.paragraph1 || "");
            setNewParagraph2(data.paragraph2 || "");
            setIsLoading(false); // Arrête le loader une fois que les données sont chargées
        });

        return () => unsubscribe();
    }, []);

    const startEditing1 = () => {
        setIsEditing1(true);
    };

    const startEditing2 = () => {
        setIsEditing2(true);
    };

    const handleUpdateParagraph1 = async (e) => {
        e.preventDefault();
        if (paragraphs.id) {
            try {
                await updateParagraph1(paragraphs.id, newParagraph1);
                setIsEditing1(false); // Cacher le formulaire après la mise à jour
            } catch (error) {
                console.error('Erreur lors de la mise à jour du paragraphe 1:', error);
            }
        }
    };

    const handleUpdateParagraph2 = async (e) => {
        e.preventDefault();
        if (paragraphs.id) {
            try {
                await updateParagraph2(paragraphs.id, newParagraph2);
                setIsEditing2(false); // Cacher le formulaire après la mise à jour
            } catch (error) {
                console.error('Erreur lors de la mise à jour du paragraphe 2:', error);
            }
        }
    };

    return (
        <>
            <div hidden={hidden}>
                <h1>Qui sommes-nous</h1>
                    {isLoading ? (
                        <Loader />
                        ) : (
                            <>
                                <div className="content">
                                    <div className="text-container">
                                        {!isEditing1 ? (
                                            <>
                                                <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: paragraphs.paragraph1 }}></p>
                                                {isAdmin && (
                                                    <button className="edit-button" onClick={startEditing1}>Modifier le texte</button>
                                                )}
                                            </>
                                        ) : (
                                            <form className="test-form" onSubmit=       {handleUpdateParagraph1}>
                                                <textarea
                                                    className="expanded-textarea"
                                                    value={newParagraph1}
                                                    onChange={(e) => setNewParagraph1(e.target.value)}
                                                    placeholder="Entrez le texte du premier paragraphe"
                                                />
                                                <div className="button-container">
                                                    <button className="edit-button" type="submit">Mettre à jour</button>
                                                    <button className="edit-button" type="button" onClick={() => setShowHelp(true)}>Aide</button>
                                                    <button className="edit-button" type="button" onClick={() => setIsEditing1(false)}>Annuler</button>
                                                </div>
                                            </form>
                                         )}
                                    </div>
                                    <div className="image-container">
                                        <img src={Photovic} alt="Photo de profil" className="Photo" />
                                    </div>
                                </div>
                                <div className="content-2">
                                    <div className="texte-environnement">
                                        {!isEditing2 ? (
                                            <>
                                                <p style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: paragraphs.paragraph2 }}></p>
                                                {isAdmin && (
                                                    <button className="edit-button" onClick={startEditing2}>Modifier le texte</button>
                                                )}
                                            </>
                                        ) : (
                                            <form className="edit-form" onSubmit={handleUpdateParagraph2}>
                                                <textarea
                                                    className="expanded-textarea"
                                                    value={newParagraph2}
                                                    onChange={(e) => setNewParagraph2(e.target.value)}
                                                    placeholder="Entrez le texte du deuxième paragraphe"
                                                />
                                                <div className="button-container">
                                                    <button className="edit-button" type="submit">Mettre à jour</button>
                                                    <button className="edit-button" type="button" onClick={() => setShowHelp(true)}>Aide</button>
                                                    <button className="edit-button" type="button" onClick={() => setIsEditing2(false)}>Annuler</button>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </div>
                                <Footer setShowMentionsLegales={toggleFooter} />
                            </>
                        )}
            </div>
            {showHelp && (
                        <div className="help-overlay">
                            <div className="help-content">
                                <h2>Aide</h2>
                                <p>Pour mettre un texte en gras : <code>&lt;b&gt;texte&lt;/b&gt;</code>
                                    <br/>
                                    Pour mettre un texte en italique : <code>&lt;i&gt;texte&lt;/i&gt;</code>
                                    <br/>
                                    Pour souligner du texte : <code>&lt;u&gt;texte&lt;/u&gt;</code>
                                    <br/>
                                    <br/>
                                    Pour modifier la taille du texte : <code>&lt;span style=&quot;font-size: 16px;&quot;&gt;texte&lt;/span&gt;</code> (16 correspond à la taille normal)
                                    <br/>
                                    <br/>
                                    Pour mettre du texte en bleu : <code>&lt;span style=&quot;color: blue;&quot;&gt;texte&lt;/span&gt;</code> (les couleurs sont en anglais)
                                    <br/> 
                                    Si tu veux des couleurs plus précises tu peux aller sur ce site : https://htmlcolorcodes.com/fr/ et tu prends la couleur en héxadécimale. 
                                    <br/> 
                                    <br/> 
                                    La couleur du tronc de l'arbre (vert foncé) en héxadécimale c'est #2A4C21
                                    <br/> 
                                    La couleur des feuilles de l'arbre (vert clair) en héxadécimale c'est #83BC2A
                                    <br/> 
                                    La couleur de la citrouille (orange) en héxadécimale c'est #F07D0C
                                    <br/> 
                                    <br/> 
                                    Du coup pour mettre du texte de la couleur du tronc de l'arbre tu fais : <code>&lt;span style=&quot;color: #2A4C21;&quot;&gt;texte&lt;/span&gt;</code>
                                    <br/> 
                                    <br/> 
                                    Enfin si maintenant tu veux mettre un texte en rouge et que tu veux aussi le souligner (par exemple) tu fais : <code>&lt;span style=&quot;color: red;&quot;&gt;&lt;u&gt;texte&lt;/u&gt;&lt;/span&gt;</code>
                                </p>
                                <div className="button-container">
                                    <button onClick={() => setShowHelp(false)}>Fermer</button>
                                </div>
                            </div>
                        </div>
                    )}
            

        </>
    );
};

export default QuiSommesNous;